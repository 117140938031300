import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { axiosPrivate } from '../../../../axiosInstance';

type ModalType = 'oldPrice' | 'savedPrice';

type ModalState = {
  oldPrice: boolean;
  savedPrice: boolean;
};

type LoadingState = {
  oldPrice: boolean;
  savedPrice: boolean;
};

const UpdatePricingMain: React.FC = () => {
  const [showModal, setShowModal] = useState<ModalState>({ oldPrice: false, savedPrice: false });
  const [isLoading, setIsLoading] = useState<LoadingState>({ oldPrice: false, savedPrice: false });

  const handleUpdateClick = (type: ModalType) => {
    setShowModal((prev) => ({ ...prev, [type]: true }));
  };

  const handleUpdateConfirm = async (type: ModalType) => {
    setIsLoading((prev) => ({ ...prev, [type]: true }));
    try {
      const endpoint = type === 'oldPrice' ? '/update-old-price' : '/update-old-saved';
      const res = await axiosPrivate.get(endpoint);
      if (res.status === 200) {
        setIsLoading((prev) => ({ ...prev, [type]: false }));
        setShowModal((prev) => ({ ...prev, [type]: false }));
      }
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, [type]: false }));
      console.error(error);
    }
  };

  const handleCancel = (type: ModalType) => {
    setShowModal((prev) => ({ ...prev, [type]: false }));
  };

  return (
    <>
      <Button variant="primary" onClick={() => handleUpdateClick('oldPrice')}>
        Update Deposit Due Pricing
      </Button>
      <Button variant="secondary" onClick={() => handleUpdateClick('savedPrice')} className="ms-2">
        Update App Started Pricing
      </Button>

      {/* Modal for Updating Old Pricing */}
      <Modal show={showModal.oldPrice} onHide={() => handleCancel('oldPrice')}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to update all pricing for Incomplete Deposit Due?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCancel('oldPrice')}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateConfirm('oldPrice')}
            disabled={isLoading.oldPrice}
          >
            {isLoading.oldPrice ? 'Updating...' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Updating Saved Pricing */}
      <Modal show={showModal.savedPrice} onHide={() => handleCancel('savedPrice')}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to update all saved pricing data?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCancel('savedPrice')}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateConfirm('savedPrice')}
            disabled={isLoading.savedPrice}
          >
            {isLoading.savedPrice ? 'Updating...' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePricingMain;

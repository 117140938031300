import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { axiosPrivate } from '../../axiosInstance';
import PaymentProtectionPlan from '../modules/application/PaymentProtectionPlan';
import { downloadFile } from '../../network/api';
import useAppRequests from '../modules/application/Hooks/useAppRequests';
import { IDiscountInfo } from '../modules/application/core/_models';
import { capitalizeFirstLetter } from '../../utils/funcHelpers';

interface PaymentSummaryProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

interface IScholarship {
  id: number
  description: string
  due: number
  price: number
  transaction_type: string
}

interface IScholarshipCodeMsg {
  success?: string;
  error?: string;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({ formData, setFormData }) => {
  const { useVerifyDiscountCode } = useAppRequests();
  const verifyDiscount = useVerifyDiscountCode();

  const { selectedProgram, student_Type } = formData;
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isTPPChecked, setIsTPPChecked] = useState<boolean>(false);
  const [isWaitlist, setisWaitlist] = useState<boolean>(false);
  const [tuitionPlan, setTuitionPlan] = useState<any>([]);
  const [programSummary, setProgramSummary] = useState<any>('');
  
  // Scholarship State
  const [discountCodes, setDiscountCodes] = useState<IScholarship[]>([]);
  const [discountCode, setDiscountCode] = useState<string>('');
  const [scholarshipCodeMsg, setScholarshipCodeMsg] = useState<IScholarshipCodeMsg>({});

  const ProgramDue = 600; 
  const getProgramPrice = () => {
    if (!selectedProgram || !student_Type) return 0;
    const pricing = selectedProgram.pricing.find(
      (price: any) => price.tuitionName.toLowerCase() === student_Type.toLowerCase()
    );

    return pricing ? pricing.price : 0;
  };

  useEffect(() => {
    const savedScholarships = localStorage.getItem('scholarshipCodes');
    if (savedScholarships) {
      setDiscountCodes(JSON.parse(savedScholarships));
    }
    
    getProgramPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('scholarshipCodes', JSON.stringify(discountCodes));
  }, [discountCodes]);

  const getEnrollmentExtras = () => {
    const extras = sessionStorage.getItem('selectedExtras');
    return extras ? JSON.parse(extras) : [];
  };

  const getEnrollmentExtrasTotal = () => {
    const enrollment_extras = getEnrollmentExtras();
    if (!enrollment_extras) return 0;
    return enrollment_extras.reduce((total: number, extra: any) => total + extra.pricing, 0);
  };

  const getNonRefundableFee = () => {
    const enrollment_extras = getEnrollmentExtras();
    if (!enrollment_extras) return 0;
    const fee = enrollment_extras.find((extra: any) => extra.isRequired === true);
    const couponCodes = discountCodes.filter((item: IScholarship) => item.transaction_type === 'coupon');
    const reduceDue = couponCodes.reduce((total: number, coupon: IScholarship) => total + coupon.price, 0);
    return fee ? Math.max(fee.pricing - reduceDue, 0) : 0;
  };

  const unchangedNonRefundableFee = () => {
    const enrollment_extras = getEnrollmentExtras();
    if (!enrollment_extras) return 0;
    const fee = enrollment_extras.find((extra: any) => extra.isRequired === true);
    return fee ? fee.pricing : 0;
  }
  const getProgramsDue = () => {
    const tuitionPortion = 600;
    const couponCodes = discountCodes.filter((item: IScholarship) => item.transaction_type === 'coupon');
    const reduceDue = couponCodes.reduce((total: number, coupon: IScholarship) => total + coupon.due, 0);
    return Math.max(tuitionPortion-reduceDue, 0);
  }

  const getSupplementPrice = () => {
    if (!selectedProgram?.programData?.supplement) return 0;
    return selectedProgram?.programData?.supplement?.price || 0;
  };

  const getDiscountPrice = () => {
    const discountCodesApplied = discountCodes.filter((item: IScholarship) => item.transaction_type === 'discount' || item.transaction_type === 'scholarship');
    const totalScholarshipDiscount = discountCodesApplied.reduce((acc, scholarship) => acc + (scholarship.price || scholarship.due), 0);

    return Math.max(totalScholarshipDiscount, 0);
  }

  const calculateTotalAmount = () => {
    const programPrice = getProgramPrice();
    const enrollmentExtrasTotal = getEnrollmentExtrasTotal();
    const supplementPrice = getSupplementPrice();
    const TPPPrice = tuitionPlan && isTPPChecked ? tuitionPlan?.pricing : 0;
    return Math.max(Number(programPrice) + Number(enrollmentExtrasTotal) + Number(supplementPrice) - getDiscountPrice(), 0) + Number(TPPPrice);
  }

  const calculateRemainingBalance = () => {
    const totalAmount = calculateTotalAmount();
    const totalDueNow = getProgramsDue() + getNonRefundableFee() + (tuitionPlan && isTPPChecked ? tuitionPlan?.pricing : 0);
    return Math.max(totalAmount - totalDueNow,0);
  };

  useEffect(() => {
    setIsChecked(false);

    const tuitionPlanFromSession = sessionStorage.getItem('tuition_plan');
    setIsTPPChecked(tuitionPlanFromSession === 'true'); // Convert from string to boolean if needed
  }, []);

    // Checkbox change handlers
    const handleCheckboxChange = () => {
      const newCheckedState = !isChecked;
      setIsChecked(newCheckedState);
  
      // Update formData
      setFormData((prevData: any) => ({
        ...prevData,
        tnc: newCheckedState,
      }));
    };
  
    const handleTPPCheckboxChange = () => {
      const newTPPCheckedState = !isTPPChecked;
      setIsTPPChecked(newTPPCheckedState);
      sessionStorage.setItem('tuition_plan', newTPPCheckedState.toString()); // Store in sessionStorage
  
      // Update formData
      setFormData((prevData: any) => ({
        ...prevData,
        tuition_plan: newTPPCheckedState,
      }));
    };

  useEffect(() => {
    const fetchTuitionPlan = async () => {
      try {
        const response = await axiosPrivate.get('/terra-programs/fetch-tuition-plan')
        setTuitionPlan(response.data)
      } catch (error) {
        console.log(error);
      }
    }

    fetchTuitionPlan()
  }, [])

  useEffect(() => {
    const ReviewLedgerFetch = async () => {
      const response = await axiosPrivate.post('/review-ledger', formData);
      setProgramSummary(response.data.data);
    };

    ReviewLedgerFetch();
  }, [formData]);

  const downloadPdfHandler = async () => {
    downloadFile()
  };

  const submitCode = async () => {
    try {
      const data = await verifyDiscount.mutateAsync(discountCode);

      const isDiscountApplied = 
        formData.discounts.some((item: IDiscountInfo) => data.id === item.id)
      if (isDiscountApplied) 
        return setScholarshipCodeMsg({ error: `You've already used this ${data.transaction_type} code` })

      const isCouponApplied = formData.discounts.some((item: IDiscountInfo) => item.transaction_type === 'coupon')
      if (data.transaction_type === 'coupon' && isCouponApplied) return setScholarshipCodeMsg({ error: 'You can only use one coupon per application' })
      if (data.expired) return setScholarshipCodeMsg({ error: 'The code you have entered has expired' });

      setDiscountCodes((prevCodes) => [...prevCodes, data]);
      setScholarshipCodeMsg({ success: capitalizeFirstLetter(`${data.transaction_type} code applied successfully!`) });
      setFormData((prevData: any) => ({
        ...prevData,
        discounts: [
          ...(prevData.discounts || []), 
          data,
        ],
      }));
    } catch (error) {
      setScholarshipCodeMsg({ error: 'The code you have entered does not exist - please confirm and re-submit' });
    } finally {
      setDiscountCode('');
      setTimeout(() => {
        setScholarshipCodeMsg({ });
      }, 3000);
    }
  }

  useEffect(() => {
    const totalDueNow = getProgramsDue() + getNonRefundableFee() + (isTPPChecked ? tuitionPlan?.pricing : 0);
    const fullPayment = calculateTotalAmount() > 600 ? calculateTotalAmount() : totalDueNow;

    setFormData((prevData: any) => ({
        ...prevData,
        total: calculateTotalAmount(),
        payment_due: totalDueNow,
        payment_full: fullPayment,
    }));
      
  }, [isTPPChecked, isChecked, discountCodes]);

  useEffect(() => {
    const isWaitlistRaw = sessionStorage.getItem('isWaitlist');
    const isWaitlistCheck = isWaitlistRaw ? isWaitlistRaw === 'true' : false;
    setisWaitlist(isWaitlistCheck);
  })

  return (
    <>
      <PaymentProtectionPlan handleTPPCheckboxChange={handleTPPCheckboxChange} isChecked={isTPPChecked} />
      <div className='card mx-3' style={{ fontSize: 18 }}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='rounded-start'>
                    <h1 className='fw-bold'>Program<span className='fw-light'>{`${formData?.student_first_name ? ': ' +formData?.student_first_name : ''}`}</span></h1>
                  </th>
                  <th className='rounded-end text-end px-2'>
    
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='ps-4'>
                    <span>{programSummary?.program_summary}</span>
                  </td>
                  <td className='text-end px-2'>${Number(getProgramPrice()).toLocaleString()}</td>
                </tr>
                <tr>
                  <td className='fw-bold ps-4'>Supplements & Extras</td>
                </tr>
                {selectedProgram?.programData?.supplement && Object.keys(selectedProgram?.programData?.supplement).length > 0 && (
                  <tr>
                    <td className='ps-20'>{selectedProgram?.programData?.supplement?.name}</td>
                    <td className='text-end px-2'>${selectedProgram?.programData?.supplement?.price?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  </tr>
                )}
                {getEnrollmentExtras().map((extra: any) => (
                  <tr key={extra.id}>
                    <td className='ps-20'>{extra.supplement_name}</td>
                    <td className='text-end px-2'>
                      ${extra.pricing.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </td>
                  </tr>
                ))}
                {isTPPChecked && (
                  <tr>
                    <td className='ps-20'>Tuition Protection Plan</td>
                    <td className='text-end px-2'>
                      ${tuitionPlan?.pricing?.toLocaleString(undefined, { minimumFractionDigits: 2 }) || 0}
                    </td>
                  </tr>
                )}
                {discountCodes.length > 0 && (
                  <>
                    <tr>
                      <td className='fw-bold ps-4'>Scholarships & Discounts</td>
                    </tr>
                    {discountCodes.map((discount, index) => {
                      if (discount?.transaction_type === 'discount' || discount?.transaction_type === 'scholarship') {
                        return (
                          <tr key={index}>
                            <td className='ps-20'>{discount?.description}</td>
                            <td className='text-end px-2'>- ${(discount?.price || discount?.due).toFixed(2)}</td>
                          </tr>
                        )
                      }
                    })}
                  </>
                )}
                <tr>
                  <td className='ps-4 fw-bold'>Total</td>
                  <td className='text-end fw-bold px-2'>
                    ${calculateTotalAmount().toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>

                <tr>
                  <td></td>
                </tr>

                { !isWaitlist && (
                  <>
                   <tr>
                  <td className='ps-4'>Deposit Due Now (Portion of Tuition)</td>
                  <td className='text-end px-2'>
                    ${ProgramDue.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>

                {isTPPChecked && (
                  <tr>
                    <td className='ps-4'>Tuition Protection Plan</td>
                    <td className='text-end px-2'>
                      ${tuitionPlan?.pricing?.toLocaleString(undefined, { minimumFractionDigits: 2 }) || 0}
                    </td>
                  </tr>
                )}
                
                <tr key="app-fee">
                  <td className='ps-4'>Application Fee</td>
                  <td className='text-end px-2'>
                    ${unchangedNonRefundableFee().toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
                {discountCodes.length > 0 && (
                  <>
                    {discountCodes.map((scholarship, index) => {
                      if(scholarship?.transaction_type === 'coupon') {
                        const hasDue = Math.max(scholarship?.due, 0) === 0 ? (<></>) : (
                          <tr key={index + '-sub-1'}>
                            <td className='ps-20'>Due Deduction</td>
                            <td className='text-end px-2'>- ${Math.max(scholarship?.due, 0).toFixed(2)}</td>
                          </tr>
                        )
                        const hasAmount = Math.max(scholarship?.price, 0) === 0 ? (<></>) : (
                          <tr key={index + '-sub-2'}>
                            <td className='ps-20'>Application Fee</td>
                            <td className='text-end px-2'>- ${Math.max(scholarship?.price, 0).toFixed(2)}</td>
                          </tr>
                        )
                        return (
                          <>
                            <tr key={index}>
                              <td className='ps-4'>{scholarship?.description}</td>
                              <td className='text-end px-2'></td>
                            </tr>
                            {hasDue}
                            {hasAmount}
                          </>

                        )
                      }
                    })}
                  </>
                )}
                <tr>
                  <td className='ps-4 fw-bold'>Total Due Now</td>
                  <td className='text-end fw-bold px-2'>
                    ${(getNonRefundableFee() + getProgramsDue() + (tuitionPlan && isTPPChecked ? tuitionPlan?.pricing : 0)).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
                <tr>
                  <td className='ps-4'>Remaining Balance due later</td>
                  <td className='text-end px-2'>
                    ${calculateRemainingBalance().toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
                  </>
                ) }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='m-3'>
        {scholarshipCodeMsg.success && <Alert variant='success'>{scholarshipCodeMsg.success}</Alert>}
        {scholarshipCodeMsg.error && <Alert variant='danger'>{scholarshipCodeMsg.error}</Alert>}
      </div>

      {formData?.discounts.length < 1 && (
        <div className='card p-4 m-3 d-flex flex-column fs-3 gap-3'>
          <h4 className='fw-light'>
            If you were given a discount code, please enter it below:
          </h4>
          <span className='fw-bold' style={{fontSize: '15px'}}>
            All codes are case-sensitive.
          </span>

          <div className='d-flex fs-3 gap-3 col-md-3' style={{ width: '370px' }}>
            <input
              className='form-control form-control-lg'
              placeholder='Discount Code'
              name='discountCode'
              maxLength={30}
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <Button 
              className='btn btn-hover w-100 fw-bolder fs-5' 
              onClick={submitCode}
              disabled={verifyDiscount.isLoading || !discountCode}>
              {verifyDiscount.isLoading ? 'Submiting...' : 'Submit Code'}
            </Button>
          </div>
        </div>
      )}

      <div className='d-flex align-items-center gap-2 form-check mx-3'>
        <input
          style={{
            width: 23,
            height: 23,
            cursor: 'pointer'
          }}
          type='checkbox'
          className='form-check-input'
          id='termsCheckbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className='d-flex align-items-center gap-2 form-check-label fs-5 mb-0' htmlFor='termsCheckbox'>
          I acknowledge that I have read and agree to the{' '}
          <Button variant='link' onClick={downloadPdfHandler} className='p-0 fs-5'>
            Terms and Conditions
          </Button>
          <span className='text-danger fs-3'>*</span>
        </label>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your Terms and Conditions content goes here.</p>
          <p>Ensure that all relevant information is included.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentSummary;

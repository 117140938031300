import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import moment from 'moment';
import './BillingInvoice.scss'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../modules/auth';
import { currencyFormat } from '../../utils/funcHelpers';
import ScrollArrow from './ScrollDownArrow';

interface Session {
  id: number;
  status: string;
  end_date: string;
  created_at: string;
  program_id: number;
  start_date: string;
  updated_at: string;
  max_capacity: number;
  session_name: string;
  commuter_capacity: number | null;
  current_enrollment: number | null;
  residential_capacity: number | null;
}

interface Pricing {
  price: string;
  description: string;
  tuitionName: string;
}

interface Supplement {
  name: string;
  price: number;
}

interface ProgramData {
  campus: string;
  segment: string;
  countries: string;
  focusAreas: string;
  supplement?: Supplement;
  description: string;
  segmentName: string;
  subFocusAreas: string;
  experienceType: string;
}

interface SelectedProgram {
  id: number;
  name: string;
  images: any[]; 
  pricing: Pricing[];
  sessions: Session[];
  highlights: any[];
  createdDate: string;
  programData: ProgramData;
  lastModifiedDate: string;
  uniqueIdentifier: string;
}

interface LedgerTransaction {
  transaction_type: string;
  description: string;
  balance: number;
  paid: number;
  created_at: string;
}

interface TuitionType {
  id: number;
  status: string;
  created_at: string;
  segment_id: number;
  updated_at: string;
  tuition_name: string;
}

interface Supplement {
  id: number;
  pricing: number;
  category: string;
  course_id: number | null;
  created_at: string;
  isRequired: boolean;
  segment_id: number | null;
  updated_at: string;
  modified_by: string;
  product_type: string;
  tuition_type: TuitionType[];
  supplement_name: string;
}

export interface Traveler {
  id: number;
  traveler_uuid: string;
  account_holder_uuid: string;
  student_type: string;
  selected_session: Session;
  enrollment_track: string;
  selected_program: SelectedProgram;
  created_at: string;
  updated_at: string;
  guardian_uuid: string | null;
  application_status: string | null;
  enrollment_extras: Supplement | null;
  program_uuid: string;
  payment_schedule: string | null;
  ledger_transaction: LedgerTransaction[];
  due_schedules: DueSchedule[];
  studentName?: string;
}

interface StudentContacts {
  id: number;
  first_name: string;
  last_name: string;
}

interface TravelerInfo {
  id: number;
  status: string;
  type: string;
  account_holder: string;
  contact_id: string;
  relationship: string;
  sub_type: string;
  student_contacts: StudentContacts;
}

interface LedgerTransaction {
  transaction_type: string;
  description: string;
  balance: number;
  paid: number;
  created_at: string;
}

interface DueSchedule {
  id: number;
  program_id: string;
  due_date: string;
  balance: number;
  isPaid: boolean;
  created_at: string;
  updated_at: string;
}

interface BillingData {
  traveler_info: TravelerInfo;
  program: Traveler[];
}

const BillingInvoice: React.FC = () => {
  const [billingData, setBillingData] = useState<BillingData[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<Traveler[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const { currentUser } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchBillingInvoice = async () => {
      try {
        const response = await axiosPrivate.get('/billing-invoice');
        setBillingData(response.data.data);
      } catch (error) {
        console.error('Error fetching billing invoice:', error);
      }
    };

    fetchBillingInvoice();
  }, []);

  const handleProgramClick = (program: Traveler) => {
    setSelectedPrograms((prevSelectedPrograms) => {
      const isSelected = prevSelectedPrograms.some(p => p.id === program.id);

      if (isSelected) {
        return prevSelectedPrograms.filter(p => p.id !== program.id);
      } else {
        return [...prevSelectedPrograms, program];
      }
    });
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectedPrograms([]);
    } else {
      const allPrograms = billingData.flatMap((item) =>
        item.program.filter((program) => {
          const getPaid = program.ledger_transaction.filter((item: any) =>
            ['payment', 'discount', 'coupon', 'scholarship'].includes(item.transaction_type)
          );
          const getDue = program.ledger_transaction.filter((item: any) =>
            ['extras', 'base', 'supplements'].includes(item.transaction_type)
          );
          const totalPaid = getPaid.reduce((total: number, item: any) => total + Math.abs(item.paid), 0);
          const totalDue = getDue.reduce((total: number, item: any) => total + Math.abs(item.paid), 0);
  
          const outstandingBalance = Math.max(totalDue - totalPaid, 0);
          const isDisabled = outstandingBalance === 0;
  
          if (program.application_status === "Incomplete - Deposit Due" || isDisabled) {
            return false;
          }
  
          return true;
        })
      );
  
      setSelectedPrograms(allPrograms);
    }
    setIsSelectAll(!isSelectAll);
  };

  const getStudentNameForProgram = (program_uuid: string) => {
    
    const foundBillingData = billingData
      .flatMap((item: any) => item.program)
      .find((program: any) => program.program_uuid === program_uuid);
  
    if (foundBillingData) {

      const billingItem = billingData.find((item: any) =>
        item.program.some((program: any) => program.program_uuid === program_uuid)
      );
  

      if (billingItem && billingItem.traveler_info) {
        const traveler = billingItem.traveler_info;
        const studentName = `${traveler.student_contacts.first_name} ${traveler.student_contacts.last_name}`;
        return studentName;
      }
    }
  
    return '';
  };

  console.log(selectedPrograms)

  const handleNavigatePayment = () => {
    if (selectedPrograms.length > 0) {
      navigate(`/application/${currentUser?.currentClient}/pay-ledger`, { state: { selectedPrograms }, replace: true });
    }
  };

  const BillingState: React.FC<any> = ({ programs }) => {

    if (programs.length === 0) {
      return <div className='w-75 mx-auto my-5 fs-3'>No Programs selected, Please choose one!</div>;
    }

    const groupedDueSchedules = programs?.reduce((acc: any, program: any) => {
      program.due_schedules.forEach((schedule: any) => {
        const dueDate = moment.utc(schedule?.due_date).format('l')
        if (!acc[dueDate]) {
          acc[dueDate] = { balance: schedule?.balance };
        } else {
          acc[dueDate].balance += schedule?.balance;
        }
      });
      return acc;
    }, {});
    
    const formattedSchedules = Object.entries(groupedDueSchedules).map(([due_date, { balance }]: [string, any]) => {
      return { due_date, balance };
    });

    const totalOutStanding = programs.reduce((total: number, program: Traveler) => {
      const getAllPayable = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => !['payment', 'surcharge', 'revoked', 'discount', 'scholarship', 'removed'].includes(item.transaction_type)) : [];
      const getAllDiscounts = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => ['discount', 'scholarship'].includes(item.transaction_type)) : [];
      const getPayment = program?.ledger_transaction ? program?.ledger_transaction.filter((item: { transaction_type: string; }) => ['payment', 'revoked'].includes(item.transaction_type)) : [];
      const totalPayment = getPayment.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
      const overallPayment = getAllPayable.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
      const allDiscounts = getAllDiscounts.reduce((total: number, transaction: { paid: number; }) => total + Math.abs(transaction.paid), 0);
      const totalBalanceDue = (overallPayment - (totalPayment + allDiscounts));
      return total + Math.max(totalBalanceDue, 0);
    }, 0);

    const totalPayments = programs.reduce((total: number, program: Traveler) => {
      const programPayment = program.ledger_transaction
        .filter(item => ['payment', 'revoked'].includes(item.transaction_type))
        .reduce((sum, item) => sum + item.paid, 0);

      const programRefund = program.ledger_transaction
        .filter(item => item.transaction_type === 'refund')
        .reduce((sum, item) => sum + item.paid, 0);
      return (total + programPayment) - programRefund;
    }, 0);

    const totalProgramCost = programs.reduce((total: number, program: Traveler) => {
      const programCost = program.ledger_transaction
        .filter(item => item.transaction_type === 'base')
        .reduce((sum: number, item) => sum + item.paid, 0);
      return total + programCost;
    }, 0);

    const totalExtras = programs.reduce((total: number, program: Traveler) => {
      const extrasCost = program.ledger_transaction
        .filter(item => item.transaction_type === 'extras')
        .reduce((sum, item) => sum + item.paid, 0);
      return total + extrasCost;
    }, 0);

    const totalSupplement = programs.reduce((total: number, program: Traveler) => {
      const supplementCost = program.ledger_transaction
        .filter(item => item.transaction_type === 'supplements')
        .reduce((sum, item) => sum + item.paid, 0);
      return total + supplementCost;
    }, 0);

    const totalScholarship = programs.reduce((total: number, program: Traveler) => {
      const scholarshipTotal = program.ledger_transaction
        .filter(item => ['discount', 'scholarship'].includes(item.transaction_type))
        .reduce((sum, item) => sum + item.paid, 0);
      return total - scholarshipTotal;
    }, 0);

    const getAllTotal = (totalProgramCost + totalExtras + totalSupplement) - totalScholarship;

    return (
    <>
      <div className='mt-5 card card-body w-75 mx-auto'>
        <table>
        <thead>
          <tr className='text-muted bg-light fs-3'>
            <th>Statement</th>
            <th className='text-end'>Total Amount</th>
          </tr>
        </thead>
        <tbody className='fs-4'>
          {programs.sort((a: any, b: any) => a.id - b.id).map((program: Traveler, programIndex: number) => {

            const getSchdule = program.due_schedules || []

            const baseTransactions = program.ledger_transaction.filter(
              (ledger) => ledger.transaction_type === 'base'
            );
            const extrasTransactions = program.ledger_transaction.filter(
              (ledger) => ledger.transaction_type === 'extras'
            );
            const paymentTransactions = program.ledger_transaction.filter((ledger: { transaction_type: string }) =>
              ['payment', 'revoked', 'refund'].includes(ledger.transaction_type)
            );
            const supplementsTransactions = program.ledger_transaction.filter(
              (ledger) => ledger.transaction_type === 'supplements'
            );
            const scholarshipTransactions = program.ledger_transaction.filter(
              (ledger) => ledger.transaction_type === 'scholarship' || ledger.transaction_type === 'discount'
            );

            const totalExtras = extrasTransactions?.reduce((sum, ledger) => sum + ledger.paid, 0)
            const totalBase = baseTransactions?.reduce((sum, ledger) => sum + ledger.paid, 0)
            const totalSupplement = supplementsTransactions?.reduce((sum, ledger) => sum + ledger.paid, 0)
            const totalPayment = paymentTransactions?.filter((item) => ['payment', 'revoked'].includes(item.transaction_type)).reduce((sum, ledger) => sum + ledger.paid, 0) 
            const totalRefund = paymentTransactions?.filter((item) => ['refund'].includes(item.transaction_type)).reduce((sum, ledger) => sum + ledger.paid, 0) 

            const totalPaidandRefund = totalPayment - totalRefund
            const totalScholarship = scholarshipTransactions?.reduce((sum, ledger) => sum + ledger.paid, 0)
            const totalPerProgram = (totalExtras + totalBase + totalSupplement) + totalScholarship
            const outstandingAmount = totalPerProgram - totalPaidandRefund;
            return (
              <React.Fragment key={programIndex}>
                {/* Program Label */}
                <tr>
                  <td style={{ fontWeight: 'bold' }}>
                    Program {programIndex + 1}: {program.studentName}
                  </td>
                </tr>

                {/* Base Transactions */}
                {baseTransactions.length > 0 && (
                  <>
                    {baseTransactions.map((ledger, index) => (
                      <tr key={index}>
                        <td className='ps-10 mb-5 text-capitalize'>{ledger.description}</td>
                        <td className='text-end'>
                          {currencyFormat.format(ledger?.paid)}
                        </td>
                      </tr>
                    ))}
                  </>
                )}

                {/* Extras Label and Transactions */}
                {extrasTransactions.length > 0 && (
                  <>
                    <tr>
                      <td style={{ fontWeight: 600, margin: '15px 0 0' }}>
                        Fees & Extras
                      </td>
                    </tr>
                    {extrasTransactions.map((ledger, index) => (
                      <tr key={index}>
                        <td className='ps-10'>{ledger.description}</td>
                        <td className='text-end'>
                          {currencyFormat.format(ledger.paid)}
                        </td>
                      </tr>
                    ))}
                  </>
                )}

                {/* Supplement Transactions */}
                {supplementsTransactions.length > 0 && (
                  <>
                    <tr>
                      <td  style={{ fontWeight: 600, margin: '15px 0 0' }}>
                        Supplements
                      </td>
                    </tr>
                    {supplementsTransactions.map((ledger, index) => (
                      <tr key={index}>
                        <td className='ps-10'>{ledger.description}</td>
                        <td className='text-end'>
                          {currencyFormat.format(ledger.paid)}
                        </td>
                      </tr>
                    ))}
                  </>
                )}

                {/* Scholarship Transactions */}
                {scholarshipTransactions.length > 0 && (
                  <>
                    <tr>
                      <td style={{ fontWeight: 600, margin: '15px 0 0' }}>
                        Scholarships and Discounts
                      </td>
                    </tr>
                    {scholarshipTransactions.map((ledger, index) => (
                      <tr key={index}>
                        <td className='ps-10'>{ledger.description}</td>
                        <td className='text-end'>
                          ({currencyFormat.format(ledger.paid)})
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                <tr>
                  <td><hr/></td>
                  <td><hr/></td>
                </tr>

                <tr style={{ fontWeight: 600 }} className='fs-2'>
                  <td>Total</td>  
                  <td style={{ fontWeight: 600 }} className='text-end'>{currencyFormat.format(Math.max(totalPerProgram, 0))}</td>  
                </tr>
                {paymentTransactions.length > 0 && (
                  <>
                    <tr>
                      <td className='d-flex align-items-center gap-2' style={{ fontWeight: 600, margin: '15px 0 0' }}>Paid to Date <span className='fs-5' style={{ fontWeight: 400 }}>(Excluding any credit cards service fees)</span></td>
                      <td style={{ fontWeight: 600 }} className='text-end'>({currencyFormat.format(totalPaidandRefund)})</td>
                    </tr>
                    {paymentTransactions.map((ledger, index) => (
                      <tr key={index}>
                        <td className='ps-10'>{ledger.description}</td>
                        <td className='text-end'>
                          {ledger.transaction_type === 'refund' ? currencyFormat.format(ledger?.paid): `(${currencyFormat.format(ledger?.paid)})`}
                        </td>
                      </tr>
                    ))}
                  </>
                )}

                <br />
                <tr className='fs-3' style={{ fontWeight: 600 }}>
                  <td>Amount Outstanding</td>
                  <td style={{ fontWeight: 600 }} className="text-end">{currencyFormat.format(Math.max(outstandingAmount, 0))}</td>
                </tr>
                
                {getSchdule.length > 0 && (
                  <>
                  <div style={{ fontWeight: 600,  margin: '15px 0 0' }}>Upcoming Payments</div>
                  {getSchdule.map((scehdule, index) => (
                    <>
                      <tr key={index} className='fs-4'>
                        <td className='ps-10'>{moment.utc(scehdule?.due_date).format('l')}</td>  
                        <td className='text-end'>{currencyFormat.format(scehdule?.balance)}</td>  
                      </tr>
                    </>
                  ))}
                  </>
                )}
                {selectedPrograms.length > 1 && (
                  <>
                    <br />
                    <tr>
                      <td style={{ border: '2px black solid' }} />
                      <td style={{ border: '2px black solid' }} />
                    </tr>
                  </>
                )}
              </React.Fragment>
            );
          })}

          {selectedPrograms.length > 1 && (
            <>
              <br />
              <tr style={{ fontWeight: 600 }} className='fs-2'>
                <td>Grand Total</td>  
                <td style={{ fontWeight: 600 }} className='text-end'>{currencyFormat.format(getAllTotal)}</td>  
              </tr>
              <br />
              <tr style={{ fontWeight: 600, margin: '15px 0 0' }}>
                <td>Paid to Date</td>
                <td style={{ fontWeight: 600 }} className='text-end'>({currencyFormat.format(totalPayments)})</td>
              </tr>
              <br />
              <tr className='fs-3' style={{ fontWeight: 600 }}>
                <td>Amount Outstanding</td>  
                <td style={{ fontWeight: 600 }} className='text-end'>{currencyFormat.format(totalOutStanding)}</td>  
              </tr>
              {formattedSchedules.length > 0 && (
                <>
                <div style={{ fontWeight: 600, margin: '15px 0 0' }}>Upcoming Payments</div>
                {formattedSchedules?.map((schedule, i) => {
                  const { due_date, balance } = schedule
                  return (
                    <tr key={i}>
                      <td className='ps-10'>{moment.utc(due_date).format('l')}</td>
                      <td className='text-end'>{currencyFormat.format(balance)}</td>
                    </tr>
                )})}  
                </>
              )}
            </>
          )}
        </tbody>
        </table>
      </div>
    </>
    ) 
  };
  const hasOutstandingBalance = selectedPrograms.every(program => {
    const getPaid = program.ledger_transaction.filter((item: any) => ['payment', 'discount', 'coupon', 'scholarship'].includes(item.transaction_type));
    const getDue = program.ledger_transaction.filter((item: any) => ['extras', 'base', 'supplements'].includes(item.transaction_type));
    const totalPaid = getPaid.reduce((total: number, item: any) => total + Math.abs(item.paid), 0);
    const totalDue = getDue.reduce((total: number, item: any) => total + Math.abs(item.paid), 0);
    const outstandingBalance = Math.max(totalDue - totalPaid, 0);
    return outstandingBalance > 0; 
  });

  return (
    <div className='position-relative'>
      <div className='header-container'>
        <div
          style={{ background: '#f7942a' }}
          className="d-flex justify-content-center rounded w-75 mx-auto col-md-12"
        >
          <div className="bg-opacity-50 w-100 mx-5 text-center py-2 text-light border-radius-20">
            <h1 className="fw-bolder fs-2x text-light">Billing and Invoices</h1>
          </div>
        </div>
      </div>
      <div onClick={handleSelectAll} className={`${isSelectAll ? "selected-all" : "unselect"}`}>
        {isSelectAll ? "Deselect All" : "Select All"}
      </div>
      <div style={{ marginTop: 50 }} className="card w-75 mx-auto">
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)'}}>
          {billingData?.map((item: BillingData, index) => {
            const studentName = `${item.traveler_info.student_contacts.first_name} ${item.traveler_info.student_contacts.last_name}`;

            return (
              <div key={index} className="card-body">
                <div className="" >
                  <h4 className="card p-3 text-center">{studentName}</h4>
                </div>

                {item.program?.map((program: Traveler, idx: number) => {
                  const campusName = program?.selected_program?.programData.campus;
                  const tuitionName = program?.student_type;
                  const sessionName = `${program?.selected_session?.session_name} (${moment.utc(program.selected_session.start_date).format('MM/DD/YYYY')} - ${moment.utc(program.selected_session.end_date).format('MM/DD/YYYY')})`;
                  const programName = program.selected_program?.name;
                  const getPaid = program.ledger_transaction.filter((item: any) => ['payment', 'discount', 'coupon', 'scholarship'].includes(item.transaction_type));
                  const getDue = program.ledger_transaction.filter((item: any) => ['extras', 'base', 'supplements'].includes(item.transaction_type));
                  const totalPaid = getPaid.reduce((total: number, item: any) => total + Math.abs(item.paid), 0);
                  const totalDue = getDue.reduce((total: number, item: any) => total + Math.abs(item.paid), 0);

                  const outstandingBalance = Math.max(totalDue - totalPaid, 0);

                  const isActive = selectedPrograms.some(p => p.id === program.id);
                  const activeStyle = isActive ? { backgroundColor: '#414bb2', color: 'white' } : {};

                  if (program?.application_status === 'Incomplete - Deposit Due') return null;

                  return (
                    <div
                      key={idx}
                      className="card program-container"
                      style={{ 
                        ...activeStyle, 
                        cursor: 'pointer', 
                        padding: '10px', 
                        fontSize: 16, 
                        margin: '10px 0', 
                        transition: '0.2s ease-in-out', 
                        borderRadius: '8px' 
                      }}
                      onClick={() => handleProgramClick(program)}
                    >
                      <div className="d-flex">
                        <div className="d-flex flex-column">
                          <span style={{ fontWeight: '600' }} className='fw-bolder'>{campusName}</span>
                          <span className="text-capitalize">{tuitionName} Student</span>
                          <span>{sessionName}</span>
                          <span>{programName}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <BillingState
        programs={selectedPrograms.map((program) => ({
          ...program,
          studentName: getStudentNameForProgram(program.program_uuid),
        }))}
      />


      <div className='d-flex w-75 mx-auto my-3 justify-content-end'>
        <button 
          onClick={handleNavigatePayment} 
          disabled={selectedPrograms.length === 0 || !hasOutstandingBalance}
          className='payment-btn'
        >
          Proceed to Payment
        </button>
      </div>

      <ScrollArrow />
    </div>
  );
};

export default BillingInvoice;

import { ITuitionPlan } from '../app/modules/application/core/_models'
import { ICouponCode } from '../app/modules/apps/coupon-code-management/model'
import { IEnrollmentFormGroup } from '../app/modules/apps/enrollment-group/EnrollmentList'
import { iProgramSession } from '../app/modules/apps/program-session-management/ProgramSessionList'
import { axiosPrivate, axiosInstance } from '../axiosInstance'

// Download PDF
export const downloadFile = async () => {
  try {
    const response = await axiosPrivate.get('/generate-presigned-url');
    const downloadUrl = response.data;

    // Open the URL in a new tab
    window.open(downloadUrl, '_blank');
    
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
  }
};

// User List
export const fetchAllUser = async () => {
  try {
    const { data } = await axiosPrivate.get('/auth/get_all_users')
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAllPaymentLogs = async () => {
  try {
    const { data } = await axiosPrivate.get('/get-payment')
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Pending Actions
export const fetchAllAutoPaymentMethods = async () => {
  try {
    const { data }= await axiosPrivate.get('/get_all_auto_payments_disabled')
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchAllAutoPaymentEnabled = async (inputs?: boolean) => {
  try {
    const { data }= await axiosPrivate.get(`/get_all_auto_payments_enabled/?isViewAll=${inputs}`)
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getExportDisableAutopay = async () => {
  try {
    const data = await axiosPrivate.get('/export/pendingActionExport')
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getExportEnabledAutopay = async (inputs?: boolean) => {
  try {
    const data = await axiosPrivate.get(`/export/autopayActionExport/?isViewAll=${inputs}`)
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Tuition Protection Plan
export const addTuitionPlan = async (inputs?: ITuitionPlan) => {
  try {
    const response= await axiosPrivate.post('/add_payment_protection_plan', {inputs})
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Coupon Code
export const fetchAllCouponCode = async () => {
  try {
    const {data} = await axiosPrivate.get('/terra-admin/get_all_coupon_code')
    return data
  } catch (error) {
    throw error
  }
}

export const fetchCouponCodeById = async (coupon_code_id: any) => {
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/get_coupon_code/${coupon_code_id}`)
    return data
  } catch (error) {
    throw error
    
  }
}

export const addOrEditCouponCode = async (data: any, coupon_code_id?: string | null) => {
  try {
    const response = await axiosPrivate.post<ICouponCode>('/terra-admin/add_or_edit_coupon_code', {
      data, 
      coupon_code_id
    })
    return response
  } catch (error) {
    throw error
  }
}


// Enrollment Extras
export const fetchAllEnrollmentExtras = async () => {
  try {
    const {data} = await axiosPrivate.get('/terra-admin/get_all_enrollment_extras')
    return data
  } catch (error) {
    throw error
  }
}

export const fetchEnrollmentExtrasById = async (enrollment_extras_id?: string) => {
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/get_enrollment_extras/${enrollment_extras_id}`)
    return data
  } catch (error) {
    throw error
    
  }
}

export const addOrEditEnrollmentExtras = async (data: any, enrollment_extras_id?: string | null) => {
  try {
    const response = await axiosPrivate.post<IEnrollmentFormGroup>('/terra-admin/add_or_edit_enrollment_extras', {
      data, 
      enrollment_extras_id
    })
    return response
  } catch (error) {
    throw error
  }
}




// Enrollment Form

export const getAllEnrollmentForm = async () => {
  try {
    const res = await axiosPrivate.get('/terra-admin/get_all_enrollment_form')
    return res
  } catch (error) {
    throw error
  }
}

export const getEnrollmentFormById = async (enrollment_form_id: string | undefined) => {
  try {
    const res = await axiosPrivate.get(`/terra-admin/get_enrollment_form/${enrollment_form_id}`)
    return res
  } catch (error) {
    
  }
}

export const addOrEditEnrollmentForm = async (data: any, enrollment_form_id?: number | null) => {
  try {
    const response = await axiosPrivate.post<IEnrollmentFormGroup>('/terra-admin/add_or_edit_enrollment_form', {
      data, 
      enrollment_form_id
    })
    return response
  } catch (error) {
    throw error
  }
}

// TypeForm

export const getAllTypeFormData = async () => {
  try {
    const res = await axiosPrivate.get('/terra-admin/get_all_typeform_data')
    return res
  } catch (error) {
    throw error
  }
}

export const getAllTypeFormDataForSaveApplication = async () => {
  try {
    const res = await axiosPrivate.get('/terra-admin/get_all_typeform_data_save_application')
    return res
  } catch (error) {
    throw error
  }
}

export const getAllTypeForm = async () => {
  try {
    const res = await axiosPrivate.get('/terra-admin/get_all_typeform')
    return res
  } catch (error) {
    throw error
  }
}

export const getAllTypeFormByAdmin = async () => {
  try {
    const res = await axiosPrivate.get('/terra-admin/get_all_typeform_by_admin')
    return res
  } catch (error) {
    throw error
  }
}

export const getTypeFormById = async (id: string | undefined) => {
  try {
    const res = await axiosPrivate.get(`/terra-admin/get_typeform/${id}`)
    return res
  } catch (error) {
    
  }
}

export const addOrEditTypeForm = async (data: any, id?: string | null) => {
  try {
    const response = await axiosPrivate.post('/terra-admin/add_or_edit_typeform', {
      data, 
      id
    })
    return response
  } catch (error) {
    throw error
  }
}

// Programs

export const getAllProgram = async () => {
  try {
    const res = await axiosPrivate.get('/terra-admin/get_all_programs')
    return res
  } catch (error) {
    throw error
  }
}

export const getProgramById = async (id: string | undefined) => {
  try {
    const res = await axiosPrivate.get(`/terra-admin/get_program/${id}`)
    return res
  } catch (error) {
    
  }
}

export const addOrEditProgram = async (data: any, id?: string | null) => {
  try {
    const response = await axiosPrivate.post('/terra-admin/add_or_edit_program', {
      data, 
      id
    })
    return response
  } catch (error) {
    throw error
  }
}

// Enrollment Group

export const fetchEnrollmentFormGroupById = async (enrollment_form_group_id?: string) =>{
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/get_enrollment_form_group/${enrollment_form_group_id}`)
    return data
  } catch (error) {
    throw error
  }
}

export const getAllEnrollmentFormGroup = async (client_id: string | undefined) => {
  try {
    const response = await axiosPrivate.get(`/terra-admin/get_all_enrollment_form_groups/${client_id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const AddOrEditEnrollmentFormGroup =  async (enrollment_form_group_params: IEnrollmentFormGroup, enrollment_form_group_id?: string | null) => {
  try {
    const response = await axiosPrivate.post<IEnrollmentFormGroup>('/terra-admin/add_or_edit_enrollment_form_groups', {
      enrollment_form_group_params, 
      enrollment_form_group_id
    })
    return response
  } catch (error) {
    
  }
}

// Program Sessions

export const getAllProgramSessions = async () => {
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/get_program_sessions`)
    return data
  } catch (error) {
    throw error
  }
}

export const AddProgramSession = async (session_id: string | null, session_params: iProgramSession) => {
  try {
    const response = await axiosPrivate.post<iProgramSession>(`/terra-admin/add_or_edit_program_session`, { session_id, session_params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const fetchProgramSessionById = async (program_session_id?: string) => {
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/add_or_edit_program_session/${program_session_id}`)
    return data
  } catch (error) {
    throw error
  }
}

export const fetchEnrolleeAndSessionById = async (program_session_id?: string) => {
  try {
    const response = await axiosPrivate.get(`/terra-admin/get_session_and_enrollee/${program_session_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const exportEnrolleeAndSessionById = async (program_session_id?: string, status_id?: number) => {
  try {
    const response = await axiosPrivate.get(`/terra-admin/export/get_session_and_enrollee/${program_session_id}/${status_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteProgramSessionById = async (session_id: number) => {
  try {
    const {data} = await axiosPrivate.post(`/terra-admin/delete_program_session`, { session_id })
    return data
  } catch (error) {
    throw error
  }
}

// Users Progress

export const exportProgress = async () => {
  try {
    const res = await axiosPrivate.get(`/terra-admin/export_abandoned_shopping_cart`)
    return res
  } catch (error) {
    return error
  }
}

export const getAllProgress = async () => {
  try {
    const {data} =await axiosPrivate.get(`/terra-admin/get_all_progress`)
    return data
  } catch (error) {
    throw (error)
  }
}

export const getAllWaitlisted = async () => {
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/get_all_waitlisted`)
    return data
  } catch (error) {
    throw (error)
  }
}

export const exportWaitlist = async (search: any, date_from: string, date_to: string) => {
  try {
    const hasSearch = search.length !== 0
    const hasDates = date_to.length !== 0 && date_from.length !== 0
    let url = hasSearch || hasDates
        ? '/terra-admin/export_waitlist_report?' : '/terra-admin/export_waitlist_report'
    if (hasSearch || hasDates) {
      if (hasSearch) {
        url += `search=${search}`;
        if (hasDates) url += '&';
      }
      if (hasDates) {
        url += `date_to=${date_to}&date_from=${date_from}`;
      }
    }
    const res = await axiosPrivate.get(encodeURI(url))
    return res
  } catch (error) {
    return error
  }
}

export const removeFromWaitlist = async (oppId: number) => {
  try {
    const { data } = await axiosPrivate.post(`/remove_opportunity`, { oppId })
    return data
  } catch (error) {
    throw (error)
  }
}

export const moveToEnroll = async (oppId: number) => {
  try {
    const { data } = await axiosPrivate.post('/user/update_status', {
      label: 'Incomplete - Deposit Due',
      value: 'Incomplete - Deposit Due',
      status: 2,
      oppId: oppId
    })
    return data
  } catch (error) {
    throw (error)
  }
}

export const moveToWaitist = async (oppId: number) => {
  try {
    const { data } = await axiosPrivate.post('/user/update_status', {
      label: 'Incomplete - Waitlist',
      value: 'Incomplete - Waitlist',
      status: 18,
      oppId: oppId
    })
    return data
  } catch (error) {
    throw (error)
  }
}

export const moveToNotInterested = async (oppId: number) => {
  try {
    const { data } = await axiosPrivate.post('/user/update_status', {
      label: 'Waitlist - Not Interested',
      value: 'Waitlist - Not Interested',
      status: 20,
      oppId: oppId
    })
    return data
  } catch (error) {
    throw (error)
  }
}

export const getSavedProgress = async (clientId: string, uuid?: string) => {
  try {
    const {data} = await axiosInstance.get(`/enroll/${clientId}/${uuid}`)
    return data
  } catch (error) {
    throw error
  }
}

export const saveProgress = async (inputs: any) => {
  try {
    const {email, data, ip_address, cookie} = inputs

    const requestData = {
      email,
      data,
      ip_address,
      cookie,
    }

    const response = await axiosInstance.post('/enroll/save_progress', requestData)
    console.log(response.data, 'save progress')
    return response.data
  } catch (error) {
    console.log(error, 'error')
    throw error
  }
}

// Flights
export const getAllUsersClient = async (page: number, items_per_page: number) => {
  const {data} = await axiosPrivate.get(
    `/auth/get_all_users?page=${page}&items_per_page=${items_per_page}`
  )
  return data
}

export const getAllFlight = async () => {
  try {
    const {data} = await axiosPrivate.get('/terra-admin/flight')
    return data
  } catch (err) {
    console.log(err, 'Error getting flight data')
  }
}

export const searchByKeyword = async (keyword: string) => {
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/flight/search/${keyword}`)
    return data
  } catch (error) {
    console.log(error, 'Error searching')
  }
}

export const addFlight = async (inputs: any) => {
  try {
    const {data} = await axiosPrivate.post('/terra-admin/flight/add', inputs)
    return data
  } catch (error) {
    console.log(error, 'error')
    throw error
  }
}

export const getEditFlight = async (flightId: string | undefined) => {
  try {
    const {data} = await axiosPrivate.get(`/terra-admin/flight/update/${flightId}`)
    return data
  } catch (error) {
    console.log(error, 'error')
    throw error
  }
}

export const updateFlight = async (flightId: string | undefined, flightData: any) => {
  try {
    const {data} = await axiosPrivate.post(`/terra-admin/flight/update/${flightId}`, flightData)
    return data
  } catch (error) {
    console.log(error, 'error')
    throw error
  }
}

export const getFlightDropdowns = async () => {
  try {
    const {data} = await axiosPrivate.get('/terra-admin/flight/add')
    return data
  } catch (error) {
    console.log(error, 'error')
  }
}

export const createNewAirport = async (inputs: any) => {
  try {
    const {data} = await axiosPrivate.post('/terra-admin/add_airport', inputs)
    return data
  } catch (error) {
    console.log(error, 'error')
  }
}

export const createNewAirline = async (inputs: any) => {
  try {
    const {data} = await axiosPrivate.post('/terra-admin/add_airline', inputs)
    return data
  } catch (error) {
    console.log(error, 'error')
  }
}

export const getHelloSignDocumentList = async () => {
  const {data} = await axiosPrivate.get('/document')
  return data
}
export const addHelloSignDocument = async (data: any) => {
  const response = await axiosPrivate.post('/document', data)
  console.log(response, 'response')
  return response
}
export const deleteHelloSignDocument = async (id: number) => {
  const response = await axiosPrivate.delete(`/document/${id}`)
  console.log(response, 'response')
  return response
}
export const getHelloSignDocumentById = async (id: number) => {
  const response = await axiosPrivate.get(`/document/${id}`)
  console.log(response, 'response')
  return response
}
export const editHelloSignDocument = async (data: any) => {
  const response = await axiosPrivate.put(`/document`, data)
  console.log(response, 'response')
  return response
}
export const getHelloSignProgramDocumentList = async (
  client_id: string,
  program_id: string,
  document_id: string
) => {
  console.log(program_id, 'program_id')
  console.log(document_id, 'document_id')
  console.log(client_id, 'client_id')
  let url = `/programdocumentlist`
  let params = new URLSearchParams()

  if (client_id) {
    params.set('client_id', client_id)
  }
  if (program_id) {
    params.set('program_id', program_id)
  }
  if (document_id) {
    params.set('document_id', document_id)
  }

  console.log(params.toString())

  const response = await axiosPrivate.get(url + '?' + params.toString())
  console.log(response, 'response')
  return response
}
export const getProgramByClientIdAPI = async (client_id: string) => {
  const response = await axiosPrivate.get(`/enroll/${client_id}`)
  console.log(response, 'response')
  return response
}
export const getDocumentAPI = async () => {
  const {data} = await axiosPrivate.get(`/document`)
  return data
}
export const addHelloSignMappedDocument = async (data: any) => {
  const response = await axiosPrivate.post(`/programdocumentbygroup`, data)
  console.log(response, 'response')
  return response
}
export const getMappedDocumentEditAPI = async (data: any) => {
  const response = await axiosPrivate.get(`/programdocumentbydocuments?program_id=${data}`)
  console.log(response, 'response')
  return response
}
export const updateProgramStatusAPI = async (programID: number, status: string) => {
  let url = ``
  if (status === 'enabled') {
    url = `/programdocument/${programID}/disable`
  } else {
    url = `/programdocument/${programID}/enable`
  }
  const response = await axiosPrivate.get(url)
  console.log(response, 'response')
  return response
}
export const updateDefaultStudentAPI = async (data: any) => {
  let url = `/programdocument/disable`
  const response = await axiosPrivate.post(url, data)
  console.log(response, 'response')
  return response
}
export const getSelectedStudentAPI = async () => {
  let url = `/programdocument/disable`
  const response = await axiosPrivate.get(url)
  console.log(response, 'response')
  return response
}
export const getAllStudentsAPI = async () => {
  let url = `/programdocument/disable`
  const response = await axiosPrivate.get(url)
  console.log(response, 'response')
  return response
}
export const getScholarShipCodeDataAPI = async (data: any) => {
  let url = `/fetch_scholarship_code`
  const response = await axiosPrivate.post(url, data)
  console.log(response, 'response')
  return response
}
export const runAutoPayment = async () => {
  const response = await axiosPrivate.post('/run_auto_pay')
  return response
}
import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

interface PredepartureRequestModalProps {
  show: boolean;
  handleClose: () => void;
  modalMessage: string;
  isLoading: boolean;
  showSuccessMessage: { success: string, error: string };
  handleSubmitRequest: (studentUUID: string, applicationStatus: any, modalMessage: string, opportunity_id: any, predeparture_form_id: any, account_holder_uuid: any) => void;
  formData: any;
  currentUser: any;
}


const PredepartureRequestModal: React.FC<PredepartureRequestModalProps> = ({
  show,
  handleClose,
  modalMessage,
  isLoading,
  showSuccessMessage,
  handleSubmitRequest,
  formData,
  currentUser
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title>Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to request to {modalMessage}?
      {showSuccessMessage.success && (
        <Alert variant="success" className="mt-3">{showSuccessMessage.success}</Alert>
      )}
      {showSuccessMessage.error && (
        <Alert variant="danger" className="mt-3">{showSuccessMessage.error}</Alert>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" disabled={isLoading} onClick={handleClose}>Cancel</Button>
      <Button variant="primary" disabled={isLoading} onClick={() => {
        handleSubmitRequest(
        formData.studentUUID,
        formData.applicationStatus,
        modalMessage,
        formData.opportunity_id,
        formData.predeparture_form_id,
        currentUser?.uuid
        );
      }}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default PredepartureRequestModal;
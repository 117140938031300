import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

interface ApproveDenyRequestModalProps {
  show: boolean;
  handleClose: () => void;
  modalMessage: string;
  isLoading: boolean;
  showSuccessMessage: { success: string, error: string };
  handleApproveAndDenyRequest: (
    studentUUID: string,
    getAction: any,
    modalMessage: string,
    opportunity_id?: any,
    predeparture_form_id?: any,
    account_holder_uuid?: any,
    programUUID?: any,
    formID?: any
  ) => void;
  formData: any;
  getAction: any;
  currentUser?: any;
  type?: 'Predeparture';
}

const ApproveDenyRequestModal: React.FC<ApproveDenyRequestModalProps> = ({
  show,
  handleClose,
  modalMessage,
  isLoading,
  showSuccessMessage,
  handleApproveAndDenyRequest,
  formData,
  getAction,
  currentUser,
  type,
}) => {

  const handleConfirmClick = () => {
    if (type === 'Predeparture') {
      handleApproveAndDenyRequest(
        formData.studentUUID,
        getAction,
        modalMessage,
        formData.opportunity_id,
        formData.predeparture_form_id,
        currentUser?.uuid
      );
    } else {
      handleApproveAndDenyRequest(
        formData.studentUUID,
        formData.programUUID,
        formData.formID,
        getAction,
        modalMessage,
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to {['approved', 'Approve'].includes(modalMessage) ? 'approve' : 'deny'} the request?
        {showSuccessMessage.success && (
          <Alert variant="success" className="mt-3">{showSuccessMessage.success}</Alert>
        )}
        {showSuccessMessage.error && (
          <Alert variant="danger" className="mt-3">{showSuccessMessage.error}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={isLoading} onClick={handleClose}>Cancel</Button>
        <Button variant="primary" disabled={isLoading} onClick={handleConfirmClick}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveDenyRequestModal;

import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import UsersProvider from './../user-management/users-list/UsersProvider'

// Document List Components

import {useAuth} from '../../../../app/modules/auth'
import UpdatePricingMain from './UpdatePricingMain'

const UpdatePricingManagement = () => {
  const {currentUser} = useAuth()

  const update_pricing_breadcrumbs: Array<PageLink> = [
    {
      title: 'Update Pricing',
      path: `/apps/${currentUser?.currentClient}/update-pricing-management/upm`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='upm'
          element={
            <>
              <PageTitle breadcrumbs={update_pricing_breadcrumbs}>Update Pricing</PageTitle>
              <UpdatePricingMain />
            </>
          }
        />
        {/* EDIT AND ADD if needed */}
        {/* <Route
          path='add-program-session'
          element={
            <>
              <PageTitle breadcrumbs={program_session_breadcrumbs}>Add Program Session</PageTitle>
              <ProgramSesssionAdd />
            </>
          }
        /> */}
        {/* <Route
          path='edit-program-session/:program_session_id'
          element={
            <>
              <PageTitle breadcrumbs={program_session_breadcrumbs}>Edit Program Session</PageTitle>
              <ProgramSessionEdit />
            </>
          }
        /> */}
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/apps/${currentUser?.currentClient}/update-pricing-management/upm`}
          />
        }
      />
    </Routes>
  )
}

export default UpdatePricingManagement

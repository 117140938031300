import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { axiosPrivate } from '../../axiosInstance';
import moment from 'moment';

type Campus = {
  id: number;
  name: string;
  segmentId: number;
  createdDate: string;
  modifiedDate: string;
  residentialCapacity: number | null;
  commuterCapacity: number | null;
  region: string | null;
  imageUrl: string | null;
  s3Key: string | null;
  school_level: string | null;
};

type Course = {
  id: number;
  name: string;
  sessions: Array<{ id: number; name: string; session_name: string; start_date: string; end_date: string }>;
  pricing: Pricing[]
};

type Pricing = {
  tuitionName: string;
  description: string;
  price: number;
};

type Extra = {
  id: number;
  supplement_name: string;
  category: string;
  pricing: number;
  isRequired?: boolean;
  tuition_type: any[];
  created_at: string;
  updated_at: string;
};

const programSchema = Yup.object().shape({
  campus: Yup.string().required('Select a Campus'),
  course: Yup.object().required('Select a Course'), // Ensure course is validated as an object
  session: Yup.object().required('Select a Session'), // Ensure session is validated as an object
  tuitionType: Yup.string().required('Select a Tuition Type'),
});

const EditProgram = ({ programData, hasTPP, refetch}: any) => {

  const [program, setProgram] = useState(programData);
  const [courses, setCourses] = useState<Course[]>([]);
  const [sessions, setSessions] = useState<any[]>([]);
  const [campuses, setCampuses] = useState<Campus[]>([]);
  const [pricing, setPricing] = useState<Pricing[]>([]);
  const [extras, setExtras] = useState<Extra[]>([]);
  const [selectedExtras, setSelectedExtras] = useState<Extra[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [submitting, setSubmitting] = useState(false); // Add submitting state
  const [initialValues, setInitialValues] = useState({
    campus: program?.selected_program?.programData?.campus || '',
    session: program?.selected_session || '',
    extras: program?.enrollment_extras || [],
    course: program?.selected_program || '',
    tuitionType: program?.student_type || '',
    courseId: program?.selected_program.id || ''
  });

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    const filterStudentPrice = values.course.pricing.find((item: any) => item.tuitionName === values.tuitionType)

    const newValues = {
      oldValues: program,
      traveler_uuid: programData.traveler_uuid,
      account_holder_uuid: programData.account_holder_uuid,
      selected_program: {
        ...values.course,
        student_type_price: filterStudentPrice?.price
      },
      enrollment_extras: [...selectedExtras],
      guardian_uuid: null,
      selected_session: values.session,
      student_type: values.tuitionType,
      enrollment_track: program?.enrollment_track,
    };


    try {
      const response = await axiosPrivate.post(`/update-current-program/${program.program_uuid}`, newValues)
      if (response.status === 200) {
        await refetch()
      }
    } catch (error) {
      console.error('Error updating program:', error);
    } finally {
      setSubmitting(false); 
      setOpen(false);
    }
  };

  const fetchCoursesByCampusName = async (campusName: string) => {
    try {
      const { data } = await axiosPrivate.get(`/terra-programs/api/campus/${campusName}`);
      const sortedCourse = data?.data?.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setCourses(sortedCourse);
      setPricing([])
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleCourseChange = (selectedCourse: string) => {
    const selectedCourseData = courses.find((course) => course.name === selectedCourse);
    setSessions(selectedCourseData?.sessions || []);
    setPricing(selectedCourseData?.pricing || [])
  };

  const fetchSession = initialValues.course?.name
  useEffect(() => {
    if (fetchSession) {

      const selectedCourseData = courses.find(course => course.name === initialValues.course.name);
      
      if (selectedCourseData) {
        setSessions(selectedCourseData.sessions ?? []);
      } else {
        setSessions([]);
      }
    }
  }, [fetchSession, courses]);

  useEffect(() => {
    const fetchCampuses = async () => {
      try {
        const { data } = await axiosPrivate.get('/terra-programs/api/campus-list');
        const sortedCampus = data?.data?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setCampuses(sortedCampus);
      } catch (error) {
        console.error('Error fetching campuses:', error);
      }
    };

    fetchCampuses();
  }, []);

  useEffect(() => {
    if (initialValues.campus) {
      fetchCoursesByCampusName(initialValues.campus);
    }
  }, [initialValues.campus]);

  useEffect(() => {
    if (initialValues.course && courses.length > 0) {
      handleCourseChange(initialValues.course.name);
    }

    if (program?.selected_program?.pricing) {
      setPricing(program.selected_program.pricing);
    }
  }, [courses]);

  const fetchExtras = async (tuitionType: string) => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(`/terra-programs/supplements/${tuitionType}`);
      const allExtras = response.data.extras;

      const requiredExtrasList = allExtras.filter((extra: Extra) => extra.isRequired);
      const optionalExtrasList = allExtras.filter((extra: Extra) => !extra.isRequired);
      const getTPP = allExtras.filter((extra: Extra) => extra.supplement_name === 'Tuition Protection Plan');
      const forTPP = hasTPP ? getTPP : [];
      const initialExtras = initialValues.extras.filter((extra: Extra) => !extra.isRequired);

      const mergedArray: Extra[] = Array.from(new Set([...requiredExtrasList, ...initialExtras, ...forTPP]));

      const filteredMergedArray = mergedArray.filter(extra => {
        const isCommuter = extra.tuition_type?.some((item) => item.tuition_name.includes('Commuter'));
        const isResidential = extra.tuition_type?.some((item) => item.tuition_name.includes('Residential'));
        const matched = (tuitionType === 'commuter' && isCommuter) || (tuitionType === 'residential' && isResidential) || extra.supplement_name === 'Tuition Protection Plan';
        return matched
      });

      setSelectedExtras(filteredMergedArray);
      setExtras(optionalExtrasList);
    } catch (error) {
      console.error('Error fetching extras:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialValues.tuitionType) {
      fetchExtras(initialValues.tuitionType);
    }
  }, [initialValues.tuitionType]);

  const capitalize = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <>
      <Button className='btn btn-hover mx-5' variant='primary' onClick={() => setOpen(true)}>
        Edit Program
      </Button>
      <Modal backdrop='static' size='lg' show={open} onHide={() => setOpen(false)} centered>
        <Modal.Header>
          <Modal.Title>Edit Program</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={programSchema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => {

            return (
              <Form>
                <Modal.Body>
                  <div>
                    <label className='fs-3 fw-bolder' htmlFor="campus">Campus</label>
                    <Field
                      as="select"
                      name="campus"
                      className='form-select form-select-lg'
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedCampus = e.target.value;
                        setFieldValue('campus', selectedCampus);
                        fetchCoursesByCampusName(selectedCampus);
                        setFieldValue('tuitionType', '');
                        setSelectedExtras([]);
                      }}
                    >
                      <option value="" disabled>Select a campus</option>
                      {campuses.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name} - {item.region}
                        </option>
                      ))}
                    </Field>
                  </div>

                  {courses.length > 0 && (
                    <>
                      <div>
                      <label className='fs-3 fw-bolder' htmlFor="course">Course</label>
                      <Field
                        as="select"
                        name="course"
                        className="form-select form-select-lg"
                        value={values.course ? values.course.id : ''} // Ensure value is not undefined
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const selectedCourseId = e.target.value;
                          // Check if the user selected a valid course or 'Select a course'
                          if (selectedCourseId === '') {
                            setFieldValue('course', null); // Reset course field if "Select a course" is chosen
                            handleCourseChange(''); // Reset course-specific logic, e.g., sessions
                          } else {
                            const selectedCourseData = courses.find(course => course.id.toString() === selectedCourseId);
                            
                            // If valid course is found, update form field and handle course change
                            if (selectedCourseData) {
                              setFieldValue('course', selectedCourseData); // Set entire course object
                              handleCourseChange(selectedCourseData.name); // Fetch sessions based on course name
                            }
                          }
                        }}
                      >
                        <option value="" disabled>Select a course</option> {/* Ensure value is empty to represent the default option */}
                        {courses.map(course => {
                          return(
                            <option key={course.id} value={course.id}> {/* Use course ID as the value */}
                              {course.name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>

                      {sessions.length > 0 && (
                        <div>
                        <label className='fs-3 fw-bolder' htmlFor="session">Sessions</label>
                        <Field 
                          as="select" 
                          name="session" 
                          className='form-select form-select-lg'
                          value={values.session ? values.session.id : ''} // Ensure value is not undefined
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            const selectedSessionId = e.target.value;
                            
                            // Check if the user selected a valid session or 'Select a session'
                            if (selectedSessionId === '') {
                              setFieldValue('session', null); // or you can set it to an empty object if required
                            } else {
                              const selectedSessionData = sessions.find(session => session.id.toString() === selectedSessionId);
                              
                              // If valid session is found, update form field
                              if (selectedSessionData) {
                                setFieldValue('session', selectedSessionData);
                              }
                            }
                          }}
                        >
                          <option value="">Select a session</option> {/* Ensure value is empty to represent the default option */}
                          {sessions.map((session) => (
                            <option key={session.id} value={session.id}>
                              {session.session_name} - {moment(session.start_date).format('MM/DD/YYYY')} to {moment(session.end_date).format('MM/DD/YYYY')}
                            </option>
                          ))}
                        </Field>
                      </div>
                      )}
                    </>
                  )}

                  <div>
                    <label className='fs-3 fw-bolder' htmlFor="tuitionType">Tuition Type</label>
                    <Field
                      as="select"
                      name="tuitionType"
                      className='form-select form-select-lg'
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedTuitionType = e.target.value;
                        setFieldValue('tuitionType', selectedTuitionType);
                        fetchExtras(selectedTuitionType);
                      }}
                    >
                      <option value="" disabled>Select tuition type</option>
                      {pricing.map((pricingItem) => (
                        <option key={pricingItem.tuitionName} value={pricingItem.tuitionName}>
                          {capitalize(pricingItem.tuitionName)}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div>
                    <label className='fs-3 fw-bolder' htmlFor="extras">Extras (optional)</label>
                    <div>
                      {loading && <p>Loading extras...</p>}
                      {extras.map((extra) => {
                        const isChecked = selectedExtras.some((selectedExtra) => Number(selectedExtra.id) === Number(extra.id));
                        return (
                        <div className='d-flex align-items-center gap-3' key={extra.id}>
                          <Field
                            type="checkbox"
                            name="extras"
                            value={extra.id}
                            checked={isChecked}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const { checked } = e.target;
                            
                              setSelectedExtras((prev) => {
                                const alreadySelected = prev.some((item) => item.id === extra.id);
                            
                                if (checked && !alreadySelected) {
                                  return [...prev, extra]; // Add only if not already in the array
                                }
                            
                                if (!checked) {
                                  return prev.filter((item) => item.id !== extra.id); // Remove if unchecked
                                }
                            
                                return prev;
                              });
                            }}
                          />
                          <span>{extra.supplement_name} - ${extra.pricing}</span>
                        </div>
                      )})}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button disabled={submitting} variant="secondary" onClick={() => setOpen(false)}>
                    Close
                  </Button>
                  <Button 
                    variant="primary" 
                    type="submit" 
                    disabled={loading || submitting} // Disable if no changes
                  >
                    {submitting ? 'Submitting...' : 'Save Changes'}
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default EditProgram;

import React, { useState, useEffect, FormEvent } from 'react';
import './MultiStepForm.scss';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Campus from './Campus';
import SessionPage from './SessionPage';
import StudentType from './StudentType';
import ApplicationType from './ApplicationType';
import PaymentSummary from './PaymentSummary';
import PaymentOptions from './PaymentOptions';
import SelectProfile from './SelectProfile';
import SelectParentProfile from './SelectParentProfile';
import SelectExtras from './SelectExtras';
import FormsPage from './FormsPage';
import { axiosPrivate } from '../../axiosInstance';
import CongratulationsModal from '../modules/application/components/modal/CongratulationsModal';
import { getAllTypeForm, getAllTypeFormDataForSaveApplication } from '../../network/api';
import { MdKeyboardDoubleArrowDown } from 'react-icons/md';
import ScrollArrow from './ScrollDownArrow';
import useAppRequests from '../modules/application/Hooks/useAppRequests';

interface CreditCardInfo {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  billingAddress: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
}

interface BankAccountInfo {
  nameOnCard: string;
  routingNumber: string;
  accountNumber: string;
  bankType: "checking" | "savings";
}

interface PaymentInfo {
  paymentType: "bankAccount" | "creditCard" | "debitCard";
  isActive: boolean;
  creditCardInfo?: CreditCardInfo;
  bankAccountInfo?: BankAccountInfo;
}

interface DiscountInfo {
  description: string
  due: number
  price: number
  transaction_type: string
}
interface FormData {
  selectedProgram: any;
  selectedSession: any;
  student_Type: string;
  student_uuid: string;
  account_holder_uuid: string;
  enrollment_extras: any;
  enrollment_track: string;
  payment_info: PaymentInfo;
  payAmount: number;
  existingPaymentMethod: string;
  tnc?: boolean;
  payment_due?: string;
  payment_full?: string;
  payment_schedule?: string
  tuition_plan?: boolean
  discounts?: DiscountInfo[]
  program_uuid: string
  student_contact_id: any
  typeform_data: []
  contact_sf_id: string
  student_type_price: any
  opportunity_sf_id: string
}

interface RouteParams {
  client: string;
  campus: string;
  step: string;
}

const stepsConfig = [
  {
    title: "Select your Course",
    component: Campus,
    validate: (formData: FormData) => !!formData.selectedProgram,
  },
  {
    title: "Select Session",
    component: SessionPage,
    validate: (formData: FormData) => !!formData.selectedSession,
  },
  {
    title: "Please Select Your Tuition Type",
    component: StudentType,
    validate: (formData: FormData) => !!formData.student_Type,
  },
  {
    title: "Please Select Extras",
    component: SelectExtras,
    validate: () => true,
  },
  {
    title: "Please select student to enroll",
    component: SelectProfile,
    validate: (formData: FormData) => !!formData.student_uuid,
  },
  {
    title: "Please select Primary Point of Contact Guardian",
    component: SelectParentProfile,
    validate: (formData: FormData) => !!formData.account_holder_uuid,
  },
  {
    title: "Please select Application Type",
    component: ApplicationType,
    validate: (formData: FormData) => !!formData.enrollment_track,
  },
  {
    title: 'Please complete the forms to proceed',
    component: FormsPage,
    validate: (formData: FormData) => {
      return formData.enrollment_track === 'finish' &&
        !!formData.student_uuid && 
        !!formData.selectedProgram;
    },
  },
  {
    title: "Billing Summary",
    component: PaymentSummary,
    validate: (formData: FormData) => !!formData.tnc
  }
];

const MultiStepForm: React.FC = () => {
  const { step } = useParams();
  const navigate = useNavigate();
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const parsedStep = step ? parseInt(step) - 1 : 0; // Convert step to a zero-based index
  const maxStep = stepsConfig.length - 1; // Max valid step index
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const savedStep = localStorage.getItem('currentStep');
    return savedStep ? JSON.parse(savedStep) : 0;
  });
  const [formData, setFormData] = useState<FormData>(() => {
    const savedData = localStorage.getItem('multiStepFormData');
    return savedData
      ? JSON.parse(savedData)
      : {
          selectedProgram: null,
          selectedSession: null,
          account_holder_uuid: '',
          student_Type: '',
          student_uuid: '',
          enrollment_track: '',
          payment_info: null,
          enrollment_extras: null,
          tuition_plan: false,
          discounts: [],
          program_uuid: '',
          student_contact_id: '',
          tnc: false,
          typeform_data: [],
          contact_sf_id: '',
          student_type_price: null,
          opportunity_sf_id: '',
        };
  });
  const [stepCompleted, setStepCompleted] = useState<boolean[]>(() => {
    const savedSteps = localStorage.getItem('multiStepStepsCompleted');
    return savedSteps ? JSON.parse(savedSteps) : Array(stepsConfig.length).fill(false);
  });
  const [message, setMessage] = useState({ success: '', error: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateStep = (): boolean => stepsConfig[currentStep].validate(formData);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  const [getTypeFormData, setTypeFormData] = useState<any[]>([]);
  const [lastSavedData, setLastSavedData] = useState(formData);
  const formsPageIndex = localStorage.getItem('currentStep');

  useEffect(() => {
    localStorage.setItem('multiStepFormData', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    localStorage.setItem('currentStep', JSON.stringify(currentStep));
  }, [currentStep]);

  const saveApplicationProgress = async (formData: FormData) => {
    const selectedCampus = localStorage.getItem('selectedCampus') || '';
    const currentStep = localStorage.getItem('currentStep') || '';
    const selectedStudent = sessionStorage.getItem('selectedStudent') || '';
    const program_uuid = sessionStorage.getItem('program_uuid') || '';

    const newData = {
      multiStepFormData: {
        ...formData,
        tnc: false
      },
      selectedCampus,
      currentStep: Number(currentStep),
      selectedStudent,
      program_uuid
    }

    try {
      const response = await axiosPrivate.post('/save-changes', newData);
      if (response.status === 200) {
        console.log('Progress saved successfully');
      }
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  };

  useEffect(() => {
    const shouldSaveProgress = currentStep >= 5 || currentStep === stepsConfig.length - 1;

    if (shouldSaveProgress && JSON.stringify(formData) !== JSON.stringify(lastSavedData)) {
      const saveProgress = async () => {
        await saveApplicationProgress(formData);
        setLastSavedData(formData);
      };
      saveProgress();
    }
  }, [formData, currentStep]);


  useEffect(() => {
    const fetchTypeFormData = async () => {
      try {
          const { data: typeformData } = await getAllTypeForm();
          const categoryForms = typeformData.filter((form: { category: string }) => form.category === "application");

          const typeform = await getAllTypeFormDataForSaveApplication()
          const getTypeForm = typeform.data
          
          const matchedTypeform = getTypeForm.filter(
            (form: { student_uuid: string }) => String(form.student_uuid) === String(formData.student_uuid)
          );
          const matchedForms = matchedTypeform.filter((responseForm: { form_id: string }) =>
            categoryForms.some((categoryForm: { id: string }) => categoryForm.id === responseForm.form_id)
          );
          
          setTypeFormData(matchedForms);
      } catch (error) {
          console.error("Error fetching TypeForm data:", error);
      }
    }

    if ( formsPageIndex === '7' ){
        fetchTypeFormData()
      }
  }, [formsPageIndex]);

  useEffect(() => {
    const formIds = getTypeFormData.map((form) => form.form_id);
    const uniqueFormIds = new Set(formIds);

    if (uniqueFormIds.size === 3) {
      setIsNextButtonDisabled(false)
    }
  }, [getTypeFormData]);

  const handleNextStep = () => {
    if (!validateStep()) {
      alert('Please complete the current step before proceeding.');
      return;
    }
    setStepCompleted((prev) => {
      const updatedSteps = [...prev];
      updatedSteps[currentStep] = true;
      return updatedSteps;
    });
    let nextStep = currentStep + 1;
    if (
      stepsConfig[nextStep]?.component === FormsPage &&
      formData.enrollment_track === 'express'
    ) {
      nextStep += 1; 
    }
    if (nextStep <= maxStep) {
      setCurrentStep(nextStep); // Move to the next step internally
    }
  };

  const handlePrevStep = () => {
    let prevStep = currentStep - 1;
    if (
      stepsConfig[prevStep]?.component === FormsPage &&
      formData.enrollment_track === 'express'
    ) {
      prevStep -= 1; 
    }
    if (prevStep >= 0) {
      setCurrentStep(prevStep); // Move to the previous step internally
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => { 
    event.preventDefault();
    if (!validateStep()) return alert('Please complete the current step before proceeding.');
    setIsSubmitting(true);
    const isWaitlistRaw = sessionStorage.getItem('isWaitlist');
    const isWaitlist = isWaitlistRaw ? isWaitlistRaw === 'true' : false;
    const newFormData = {
      traveler_uuid: formData.student_uuid,
      account_holder_uuid: formData?.account_holder_uuid,
      student_type: formData.student_Type,
      selected_session: formData.selectedSession,
      enrollment_track: formData.enrollment_track,
      selected_program: {
        ...formData.selectedProgram,
        student_type_price: formData?.student_type_price
      },
      payment_schedule: formData.payment_schedule,
      enrollment_extras: formData.enrollment_extras,
      tuition_plan: formData.tuition_plan,
      discounts: formData.discounts,
      program_uuid: formData.program_uuid,
      full_amount: formData.payment_full,
      due_amount: formData.payment_due,
      isWaitlist,
      opportunity_sf_id: formData?.opportunity_sf_id || ''
    };
    try {
      if(isWaitlist){
        sessionStorage.setItem('forWaitlist', JSON.stringify(newFormData));
        navigate(`/application/47821104/pay-deposit/${newFormData?.program_uuid}`);
      }
      else{
        const response = await axiosPrivate.post('/enroll-program-v2', newFormData);
        const { statusCode } = response.data;
        if(response.status === 200 && statusCode === 4) {
          localStorage.removeItem('currentStep');
          localStorage.removeItem('selectedCampus');
          localStorage.removeItem('scholarshipCodes');
          localStorage.removeItem('multiStepFormData');
          sessionStorage.removeItem('selectedAppType');
          sessionStorage.removeItem('selectedParent');
          sessionStorage.removeItem('selectedStudent');
          localStorage.removeItem('student_contact_id');
          localStorage.removeItem('typeform_data');
          sessionStorage.removeItem('program_uuid');
          sessionStorage.removeItem('tnc')
          sessionStorage.removeItem('tuition_plan');
          sessionStorage.removeItem('contact_sf_id')
          sessionStorage.removeItem('forWaitlist');
          navigate(`/dashboard/47821104`);
        } else if (response.status === 200) {
          localStorage.removeItem('currentStep');
          localStorage.removeItem('selectedCampus');
          localStorage.removeItem('scholarshipCodes');
          localStorage.removeItem('multiStepFormData');
          sessionStorage.removeItem('selectedAppType');
          sessionStorage.removeItem('selectedParent');
          sessionStorage.removeItem('selectedStudent');
          localStorage.removeItem('student_contact_id');
          localStorage.removeItem('typeform_data');
          sessionStorage.removeItem('program_uuid');
          sessionStorage.removeItem('tnc')
          sessionStorage.removeItem('tuition_plan');
          sessionStorage.removeItem('contact_sf_id')
          sessionStorage.removeItem('forWaitlist');
          if(Number(newFormData.due_amount) > 0 ) {
            navigate(`/application/47821104/pay-deposit/${newFormData?.program_uuid}`);
          } else {
            navigate(`/dashboard/47821104`);
          }
        } else if (response.status === 201 && statusCode === 3) {
          localStorage.removeItem('currentStep');
          localStorage.removeItem('selectedCampus');
          localStorage.removeItem('scholarshipCodes');
          localStorage.removeItem('multiStepFormData');
          sessionStorage.removeItem('selectedAppType');
          sessionStorage.removeItem('selectedParent');
          sessionStorage.removeItem('selectedStudent');
          localStorage.removeItem('student_contact_id');
          localStorage.removeItem('typeform_data');
          sessionStorage.removeItem('program_uuid');
          sessionStorage.removeItem('tnc')
          sessionStorage.removeItem('tuition_plan');
          sessionStorage.removeItem('forWaitlist');
          if(Number(newFormData.due_amount) > 0 ) {
            navigate(`/application/47821104/pay-deposit/${newFormData?.program_uuid}`);
          } else {
            navigate(`/dashboard/47821104`);
          }
        } else if(response.status === 201) {
          console.log(response.data.message, 'response')
          setMessage({ success: '' , error: response.data.message });
        }
      }
    } catch (error: any) {
      console.log(error, 'error')
      setMessage({ success: '', error: error.message });
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setMessage({ success: '', error: '' });
      }, 3000)
    }
    setMessage({ success: '' , error: 'Already Enrolled Into This Program' });
  };

  const StepComponent = stepsConfig[currentStep].component;

  const scrollDown = () => {
    window.scrollTo({
      top: scrollPosition + window.innerHeight,
      behavior: 'smooth',
    });
  };

  const checkScrollable = () => {
    const isPageScrollable = document.documentElement.scrollHeight > window.innerHeight;
    if (isPageScrollable !== isScrollable) {
      setIsScrollable(isPageScrollable);
    }
  };

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', checkScrollable); 
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkScrollable);
    };
  }, [checkScrollable]);

  return (
    <div className="form-container">
      <form onSubmit={currentStep === maxStep ? handleSubmit : undefined}>
      <div className='fixed-buttons-container d-flex align-items-center'>
      <div style={{ width: 150 }}>
        {currentStep > 0 && (
          <Button className='w-100' style={{ fontSize: 20 }}  variant="secondary" type="button" onClick={handlePrevStep}>
            Previous
          </Button>
        )}
        {currentStep === 0 && (
          <Button className='w-100' style={{ fontSize: 20 }}  variant="secondary" type="button" onClick={() => navigate(-1)}>
            Previous
          </Button>
        )}
      </div>
      <span style={{ background: '#f7942a' }} className='d-flex justify-content-center rounded w-75 mx-auto col-md-12'>
        <div className='d-flex align-items-center justify-content-between bg-opacity-50 w-100 px-5 text-center py-2 text-light border-radius-20'>
          <div></div>
          <h1 className='fw-bolder fs-2x text-light'>{stepsConfig[currentStep].title}</h1>
          <div style={{ fontSize: 10 }}>Page {currentStep + 2}</div>
        </div>
      </span>

      <div style={{  width: 150, height: 50 }} className={`${currentStep > 0 ? 'form-nav' : 'form-navigation'}`}>
        {currentStep < stepsConfig.length - 1 && (
          <Button className='w-100' style={{ fontSize: 20 }} variant="primary" type="button" onClick={handleNextStep}
            disabled={
              (currentStep === stepsConfig.findIndex(step => step.component === SelectExtras) && !!Array.isArray(formData.enrollment_extras) && formData.enrollment_extras.length === 0) ||
              (currentStep === stepsConfig.findIndex(step => step.component === FormsPage) && isNextButtonDisabled)
            }>
            Next
          </Button>
        )}
        {currentStep === maxStep && (
          <Button style={{ fontSize: 20 }} className='w-100' variant="primary" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        )}
      </div>
      </div>
        <div className='content-main'>
          {message.error && <Alert className='mx-3' variant="danger">{message.error}</Alert>}
          {message.success && <Alert className='mx-3' variant="success">{message.success}</Alert>}
          <StepComponent formData={formData} setFormData={setFormData} />
        </div>
      </form>
      <ScrollArrow />
    </div>
  );
}

export default MultiStepForm;
import {useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {fetchAllUser} from '../../../../network/api'
import Header from '../../../../_metronic/shared-components/Header'
import {PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, setAuthPromise, useAuth} from '../../auth'
import {axiosPrivate} from '../../../../axiosInstance'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'
import {ROLES, ROLES_IDS} from '../../../routing/core/_models'

interface ILoginUser {
  id: number
  role: number
  email: string
  uuid: string
}

const UsersList = () => {
  const [userList, setUserList] = useState<[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [visibleData, setVisibleData] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const {currentUser} = useAuth()

  const getAllUserList = async () => {
    setLoading(true)
    try {
      const data = await fetchAllUser()
      setUserList(data.data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getAllUserList()
  }, [])
  useEffect(() => {
    const filteredSearch =
      search.length !== 0
        ? userList.filter(
            (item: any) =>
              item.first_name?.toLowerCase().includes(search.toLowerCase()) ||
              item.last_name?.toLowerCase().includes(search.toLowerCase()) ||
              item.email?.toLowerCase().includes(search.toLowerCase())
          )
        : userList
    setVisibleData(filteredSearch)
  }, [userList, search])

  const loginUser = async ({id, email}: ILoginUser) => {
    const currentAuth = await getAuthPromise()
    const {data: auth} = await axiosPrivate.post('/auth/login_user_by_admin', {email})

    if (auth.api_token && currentAuth) {
      currentAuth.email = currentUser?.email
      currentAuth.first_name = currentUser?.firstName || ''
      currentAuth.last_name = currentUser?.lastName || ''

      const promises = [
        setAuthPromise(currentAuth, PREV_AUTH_LOCAL_STORAGE_KEY),
        setAuthPromise(auth),
      ]

      Promise.all(promises)
        .then(async () => {
          if (!currentUser) return

          const admin_data = {
            first_name: currentUser.firstName,
            last_name: currentUser.lastName,
            email: currentUser.email,
            loginuser_id: id,
            userclient_id: currentUser.id,
            role_id: ROLES_IDS[currentUser.role as keyof typeof ROLES],
            action: 'signed-in',
          }

          await axiosPrivate.post('/add_admin_logs', admin_data)
        })
        .then(() => {
          window.location.href = '/'
        })
        .catch((error) => {
          console.error('Error setting auth:', error)
        })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        Cell: (props: any) => {
          const {first_name, last_name} = props.row.original
          return `${first_name} ${last_name}`
        },
      },
      {
        Header: 'Role',
        Cell: (props: any) => {
          const {role} = props.row.original
          return role
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Action',
        Cell: (props: any) => {
          const {role} = props.row.original
          const isParent = role === 'parent'
          return isParent ? (
            <>
              <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                Actions
                <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
              </a>
              {/* begin::Menu */}
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <a
                    className='menu-link px-3'
                    onClick={() => {
                      loginUser(props.row.original)
                    }}
                  >
                    Act as
                  </a>
                </div>
              </div>
            </>
          ) : (
            <></>
          )
        },
      },
    ],
    []
  )
  return (
    <>
      <div className='card card-body p-0'>
        <div className='d-flex align-items-center justify-content-between pe-9'>
          <div className='d-flex align-items-center'>
            <Header setSearch={setSearch} search={search} placeholder='Search' />
          </div>
          <div className='mt-5'></div>
          <div className='d-flex mt-5 justify-content-end'></div>
        </div>
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading} />
      </div>
    </>
  )
}

export default UsersList

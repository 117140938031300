import axios from 'axios'
import {BASE_API} from './constants'
import { getAuth } from '../app/modules/auth';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const config = {
  withCredentials: true,
  baseURL: BASE_API,
}

const axiosInstance = axios.create(config)

const axiosPrivate = axios.create({
  ...config,
  headers: {
    'Content-Type': 'application/json', 
  }
})

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 404 && error.response.config.url !== "/login" && error.response.config.url !== "/logout") {
      // console.log(error,'response')
      // Navigate to the 404 page
      window.location.href = '/error/404'
    }
    return Promise.reject(error)
  }
)

axiosPrivate.interceptors.request.use(
  config => {
    const auth = getAuth();
    config.headers = config.headers || {};

    if (auth?.api_token) {
      config.headers['Authorization'] = `Bearer ${auth.api_token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 404 && error.response.config.url !== "/login" && error.response.config.url !== "/logout") {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)
export {axiosInstance, axiosPrivate}

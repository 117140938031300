import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../axiosInstance';
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import { currencyFormat } from '../../utils/funcHelpers';
import './EnrolledDashboard.scss'
import ApplicationStatusModal from '../modules/application/components/modal/ApplicationStatusModal';
import ChangeStatusModal from '../modules/application/components/modal/ChangeStatusModal';
import ResubmitRequestModal from '../modules/application/components/modal/ResubmitRequestModal';
import ApproveDenyRequestModal from '../modules/application/components/modal/ApproveDenyRequestModal';
import AddDiscountModal from './AddDiscountModal';
import EditProgram from './EditProgram';
import ScrollArrow from './ScrollDownArrow';
import { EnrolledFormRenderer } from './EnrolledFormRenderer';
import { useQueryClient } from 'react-query';
import useAppRequests from '../modules/application/Hooks/useAppRequests';
import PredepartureAction from '../pages/dashboard/GLA/PredepartureAction';
import { useQuery } from 'react-query';
import EditDiscountModal from './EditDiscountModal';

const EnrolledDashboard = ({ data, refetch }: any) => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {getPredepartureTrackings, getFormEntriesV2} = useAppRequests()
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showModalConfirmationApproveDeny, setShowModalConfirmationApproveDeny] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState({ success: '', error: ''});
  const [modalMessage, setModalMessage] = useState('');
  const [getAction, setAction] = useState('');
  const [predepartures, setPredepartures] = useState<any[]>([]);
  const [oppIds, setOppIds] = useState<any>()
  const [programIds, setProgramIds] = useState<any>()
  const { data: formEntriesTest, error, refetch: refetchPredeparture } = useQuery(
    ['formEntries', oppIds], 
    async () => await Promise.all(oppIds.map((opportunityId: any) => getFormEntriesV2(opportunityId))),
    {
      enabled:!!oppIds?.length
    }
  );

  const { data: predepartureTrackings, error: errorPredepartureTracking , refetch: refetchPredepartureTracking } = useQuery(
    ['predepartureTracking', programIds], 
    async () => { 
      const responses = await Promise.all(programIds?.map((program: any) => getPredepartureTrackings(program)))
      const allPredepartures = responses?.flatMap(response => response.data || []);
      return allPredepartures; 
    }, {
        enabled:!!programIds?.length,
        onSuccess: (data) => {
        setPredepartures(data); // Update state on success
      },
    }
  );

  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [getCurrentStatus, setCurrentStatus] = useState<string>()
  const [isError, setIsError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    studentUUID: null,
    programUUID: null,
    formID: null,
    applicationStatus: null,
  });

  const [hasAdmin, setHasAdmin] = useState<any>(null)
  const queryClient = useQueryClient()

  
  const programsWithPredeparture = data.flatMap((item: any) => (
    item.program.filter((program: Record<string, any>) =>
      program.application_status.toLowerCase().includes('enrolled') ||
      program.application_status.toLocaleLowerCase().includes('ready'))
  ))


  useEffect(() => {
    const mappedProgram = programsWithPredeparture.map((program: any) => program.id)
    setProgramIds(mappedProgram)
  
  }, [programsWithPredeparture.length]);

  useEffect(() => {
    const getCurrentAuth = async () => {
      const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (PrevAuth) {
        setHasAdmin(true)
      } else {
        setHasAdmin(false)
      }
    }
    getCurrentAuth()
  }, []);
  
  useEffect(() => {
    const fetchFormEntries = async () => {
      const opportunityIds = data.flatMap((item: any) => 
        item.program.map((program: any) => program.id)
      );
      setOppIds(opportunityIds)
    };
    fetchFormEntries();
  }, []);

  useEffect(() => {
    //clear storage
    localStorage.removeItem('multiStepFormData');
    localStorage.removeItem('scholarshipCodes')
    localStorage.removeItem('currentStep');
    localStorage.removeItem('selectedCampus');
    sessionStorage.removeItem('selectedPaymentType');
    sessionStorage.removeItem('savedCreditCardInfo');
    sessionStorage.removeItem('savedBankAccountInfo');
    sessionStorage.removeItem('selectedStudent');
    sessionStorage.removeItem('selectedAppType');
    sessionStorage.removeItem('program_uuid');
    sessionStorage.removeItem('selectedExtras')
    sessionStorage.removeItem('tnc')
    sessionStorage.removeItem('tuition_plan')
    sessionStorage.removeItem('student_contact_id')
    sessionStorage.removeItem('typeform_data')
    sessionStorage.removeItem('contact_sf_id')
  }, []);

  const handleShowModal = async (student_uuid: any, program_uuid: any, form_id: any, status: string, message: string) => {
    setModalMessage(message);
    setSelectedStatus(status);
    setCurrentStatus(status)
    setShowModal(true)
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
      applicationStatus: null,
    });
  };

  const handleShowModalConfirmation = (student_uuid: any, application_status: any, program_uuid: any, form_id: any, message: string) => {
    setModalMessage(message);
    setShowModalConfirmation(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
      applicationStatus: application_status,
    });
  };

  const handleShowModalConfirmationApproveDeny = (student_uuid: any, program_uuid: any, form_id: any, action: string, message: string) => {
    setModalMessage(message);
    setAction(action);
    setShowModalConfirmationApproveDeny(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
      applicationStatus: null,
    });
  };

  
  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalConfirmation(false);
    setShowModalConfirmationApproveDeny(false);
  };

  const handleDelete = async (program_uuid: string) => {
    if (window.confirm("Are you sure you want to delete this program?")) {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.post(`/remove-application-status/${program_uuid}`);

        if (response.status === 200) {
          alert(response.data.message); 
          window.location.reload();  
        }
      } catch (error) {
        console.error('Error while deleting:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  
  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmitStatusChange = async (student_uuid: any, program_uuid: any, form_id: any, message: string) => {
    if (selectedStatus === getCurrentStatus) {
      setIsError(`The form status is already set to '${getCurrentStatus}'. No changes will be made.`)

      setTimeout(() => {
        setIsError('')
      }, 3000)

      return;
    }

    try {
      let response;
      if (message === 'Medical'){
        response = await axiosPrivate.get('/api/typeform-updateStatusMedicalForm', {
          params: { student_uuid, program_uuid, form_id, selectedStatus},
        });
      } else {
        response = await axiosPrivate.get('/api/typeform-updateStatus', {
          params: { student_uuid, program_uuid, form_id, selectedStatus},
        });
      }

      if (response.data.isUpdated) {
        refetch()
        await queryClient.prefetchQuery('refetchSavedApplication')
      }
      
    } catch (error) {
      setIsError('An error occurred while submitting the form: ' + error)
    }

    handleCloseModal()
  };

  const handleSubmitRequest = async(student_uuid: any, application_status: any, program_uuid: any, form_id: any, message: string, account_holder_uuid?: string) => {
    try {
      setIsLoading(true);
      const data = { student_uuid, application_status, program_uuid, form_id, type: message, account_holder_uuid}
      const response = await axiosPrivate.post('/api/typeform-request', data);

      if (response.status === 200) {
        setShowSuccessMessage({ success: response.data.message, error: ''})
        refetch()
      }
      
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while submitting the form: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApproveAndDenyRequest = async(student_uuid: any, program_uuid: any, form_id: any, action: any, message: string) =>{
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, action}
      if (message === 'Approve'){
        const response = await axiosPrivate.post('/api/typeform-approveRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})

          refetch()
        }
      } else {
        const response = await axiosPrivate.post('/api/typeform-denyRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})

          refetch()
        }
      }
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while processing the request: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };


  const handlePayNow = () => {
    navigate(`/billing-and-invoice/${currentUser?.currentClient}`)
  }

  const handlePayDeposit = (program_uuid: string) => {
    navigate(`/application/${currentUser?.currentClient}/pay-deposit/${program_uuid}`)
  }

  return (
    <div style={{ fontSize: 15 }}>

      {data.map((item: any, index: number) => {
        const student_uuid = item.traveler_info?.student_contacts?.uuid
        const studentName = `${item.traveler_info?.student_contacts?.first_name} ${item.traveler_info?.student_contacts?.last_name}`

        return (
          <div key={index}>
          {item.program.map((program: any, idx: number) => {
              if (program?.application_status === 'Removed') {
                return null;
              }
              const campusName = program?.selected_program.programData.campus
              const tuitionName = program?.student_type
              const sessionName = `${program?.selected_session?.session_name} (${moment.utc(program.selected_session.start_date).format('MM/DD/YYYY')} - ${moment.utc(program.selected_session.end_date).format('MM/DD/YYYY')})`
              const programName = program.selected_program.name
              const applicationStatusName = program?.application_status
              const getPayment = program?.ledger_transaction ? program?.ledger_transaction.filter((item: { transaction_type: string; }) => ['payment', 'revoked'].includes(item.transaction_type)) : [];
              const totalRefund = program?.ledger_transaction ? program?.ledger_transaction.filter((item: any) => ['refund'].includes(item.transaction_type)).reduce((sum: any, ledger: any) => sum + ledger.paid, 0) : [];
              const totalPayment = getPayment.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
              const totalPaidandRefund = totalPayment - totalRefund
              const getAllPayable = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => !['payment', 'surcharge', 'revoked', 'discount', 'scholarship', 'removed', 'adjustment'].includes(item.transaction_type)) : [];
              const getAllDiscounts = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => [ 'discount', 'scholarship'].includes(item.transaction_type)) : [];
              const overallPayment = getAllPayable.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
              const allDiscounts = getAllDiscounts.reduce((total: number, transaction: { paid: number; }) => total + Math.abs(transaction.paid), 0);
              const totalBalanceDue = (overallPayment - (totalPayment + allDiscounts));
              const hasTPP = getAllPayable.some((item: any) => item.description === 'Tuition Protection Plan');
              const deadline = moment(program.created_at).add(14, 'days').format('MMMM DD, YYYY')

              const latestDue = Math.max(totalBalanceDue, 0) !== 0 && program?.due_schedules ? program?.due_schedules : null;  
              const nextPaymentDate = latestDue?.[0]?.due_date ? moment.utc(latestDue[0].due_date).format('D MMMM YYYY') : '';
              const nextPaymentDue = latestDue?.[0]?.balance ? currencyFormat.format(latestDue[0].balance) : '';

              const scholarshipTransactions = program.ledger_transaction.filter((ledger: any) => ['discount', 'scholarship'].includes(ledger.transaction_type));
              const couponTransactions = program.ledger_transaction.filter((ledger: any) => ['coupon'].includes(ledger.transaction_type));

              const isDepositDue = program?.application_status === 'Incomplete - Deposit Due';

              const baseTransactions = program.ledger_transaction.filter(
                (ledger: { transaction_type: string; }) => ledger.transaction_type === 'base'
              );
              const extrasTransactions = program.ledger_transaction.filter(
                (ledger: { transaction_type: string; }) => ledger.transaction_type === 'extras'
              );
              const supplementsTransactions = program.ledger_transaction.filter(
                (ledger: { transaction_type: string; }) => ledger.transaction_type === 'supplements'
              );

              const totalExtras = extrasTransactions?.reduce((sum: any, ledger: { paid: any; }) => sum + ledger.paid, 0)
              const totalBase = baseTransactions?.reduce((sum: any, ledger: { paid: any; }) => sum + ledger.paid, 0)
              const totalSupplement = supplementsTransactions?.reduce((sum: any, ledger: { paid: any; }) => sum + ledger.paid, 0)
  
              const totalScholarship = scholarshipTransactions?.reduce((sum: any, ledger: { paid: any; }) => sum + ledger.paid, 0)
              const totalPerProgram = (totalExtras + totalBase + totalSupplement) + totalScholarship
              const outstandingAmount = totalPerProgram - totalPaidandRefund;
              
              return (
              <div className='card card-body my-3' key={idx}>
                <div className='d-flex flex-column gap-1 justify-content-between p-4 mb-4 border border-1 text-center' style={{ backgroundColor: '#ccc4' }}>
                  <h5 className='fw-bolder'>{studentName}</h5>
                  <h5>{campusName}: <span className="fw-normal text-capitalize">{tuitionName} Student {sessionName} {programName}</span></h5>
                  <h5>Application Status: <span className="fw-normal">{applicationStatusName || 'Incomplete - Deposit Paid'}</span></h5>
                </div>

                <div className='d-flex gap-3' style={{ alignItems: 'center' }}>
                  <div className='flex-grow-1' style={{ alignSelf: 'flex-start'}}>
                    <table className='table table-bordered table-layout-fixed text-center align-middle'>
                      <thead>
                        <tr style={{ backgroundColor: '#ccc4' }}>
                          <th>Section</th>
                          <th>Task Name</th>
                          <th>Status</th>
                          <th>Deadline</th>
                          <th style={{ width: 150 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ border: '1px solid #dee2e6' }}>
                        <EnrolledFormRenderer
                          item={item}
                          deadline={deadline}
                          program={program}
                          handleShowModal={handleShowModal}
                          handleShowModalConfirmation={handleShowModalConfirmation}
                          handleShowModalConfirmationApproveDeny={handleShowModalConfirmationApproveDeny}
                          student_uuid={student_uuid}
                          hasAdmin={hasAdmin}
                          data={data} 
                          refetch={refetch}
                        />

                        {['Enrolled - Balance Due', 'Enrolled - Paid', 'Ready'].includes(program?.application_status) ? (
                          formEntriesTest?.map((form: any) => {
                            
                            if (form.opportunity_id === program.id) {
                              return form.predeparture.map((predeparture: any, preIndex: number) => {
                                const predepartureItem = predepartures?.filter((item: any) => (item.predeparture_form_id === predeparture.id && item.opportunity_id === program.id));
                                return (
                                  <tr key={predeparture.id} className='fw-normal'>
                                    {preIndex === 0 && ( // Check if it's the first element
                                      <td
                                        className='fw-bold text-light pt-4 pb-4'
                                        style={{
                                          backgroundColor: '#a3a3a3',
                                          verticalAlign: 'middle',
                                        }}
                                        rowSpan={form.predeparture.length}
                                      >
                                        Pre-Departure
                                      </td>
                                    )}
                                    <td>{predeparture.name}</td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          predepartureItem && predepartureItem.length > 0
                                            ? predepartureItem[0].status.name.toLowerCase() === 'incomplete'
                                              ? '#e9646280'
                                              : predepartureItem[0].status.name.toLowerCase() === 'submitted - needs review'
                                                ? '#f5c311'
                                                : '#98da88'
                                            : '#e9646280'
                                      }}
                                    >
                                      {predepartureItem.length > 0 ? predepartureItem[0].status.name : 'Incomplete'}
                                    </td>
                                    <td>{moment.utc().add(predeparture.task_deadline, 'days').format('MMMM DD, YYYY')}</td>
                                    <td>
                                      {['incomplete', 'Incomplete', undefined].includes(predepartureItem[0]?.status?.name?.toLowerCase()) ? (
                                        <>
                                          <Link
                                            className='btn btn-primary'
                                            to={`/portal/${currentUser?.currentClient}/${predeparture.task_title?.toLowerCase()}/${program.id}`}
                                            state={{ 
                                              predeparture: { 
                                                ...predeparture, 
                                                predeparture_status: predepartureItem && predepartureItem.length > 0 ? predepartureItem : [], 
                                                student_uuid: student_uuid, 
                                                program_uuid: program?.program_uuid,
                                                sf_id: item.traveler_info?.student_contacts?.sf_id,
                                              }
                                            }}
                                            style={{ width: '100px' }}
                                          >
                                            Submit
                                          </Link>
                                        </>
                                      ) : (
                                        <>
                                          <PredepartureAction
                                            hasAdmin={hasAdmin}
                                            predeparture={predeparture}
                                            predepartureStatus={predepartureItem.length > 0 ? predepartureItem : []}
                                            program={program}
                                            student_uuid={student_uuid}
                                            sf_id={item.traveler_info?.student_contacts?.sf_id}
                                            refetch={refetchPredepartureTracking}
                                          />
                                        </>
                                      )}
                                      
                                    </td>
                                  </tr>
                                )
                              });
                            }
                            return null;
                          })
                        ) : ''}
                      </tbody>
                    </table>
                    <footer>
                      <div>
                        {hasAdmin && (
                          <ApplicationStatusModal
                            refetch={refetch}
                            program={program}
                          />
                        )}
                        {hasAdmin && (
                          <AddDiscountModal
                            program_uuid={program.program_uuid}
                            refetch={refetch} 
                          />  
                        )}
                        {hasAdmin && (
                          <EditProgram programData={program} hasTPP={hasTPP} refetch={refetch}/>
                        )}
                      </div>
                    </footer>
                  </div>
                </div>

                {!!(scholarshipTransactions.length || couponTransactions.length) && (
                  <table className='table table-bordered table-layout-fixed text-center mt-4'>
                    <tr style={{ backgroundColor: '#ccc4' }}>
                      <th className='border border-1' rowSpan={scholarshipTransactions.length + couponTransactions.length + 2}>Discounts</th>
                      <th className='border border-1 pt-3 pb-3'>Type</th>
                      <th className='border border-1 pt-3 pb-3'>Description</th>
                      <th className='border border-1 pt-3 pb-3'>Amount</th>
                    </tr>

                    {scholarshipTransactions.map((ledger: any) => (
                      <tr key={ledger.id}>
                        <td className='text-capitalize border border-1 pt-3 pb-3 fw-normal'>{ledger.transaction_type}</td>
                        <td className='border border-1 pt-3 pb-3 fw-normal'>{ledger.description}</td>
                        <td className='border border-1 pt-3 pb-3 fw-normal'>{currencyFormat.format(ledger.paid)}</td>
                        {hasAdmin && (
                          <td>
                              <div className='d-flex justify-content-center mt-1 mb-2'>
                                <EditDiscountModal program_uuid={program.program_uuid} ledger_id={ledger.id} ledger_type={ledger.transaction_type} refetch={refetch} />
                              </div>
                          </td>
                        )}
                      </tr>
                    ))} 

                    {couponTransactions.map((ledger: any) => (
                      <tr key={ledger.id}>
                        <td className='text-capitalize border border-1 pt-3 pb-3 fw-normal'>{ledger.transaction_type}</td>
                        <td className='border border-1 pt-3 pb-3 fw-normal'>{ledger.description}</td>
                        <td className='border border-1 pt-3 pb-3 fw-normal'>{currencyFormat.format(ledger.paid)}</td>
                      </tr>
                    ))}
                  </table>
                )}

                {program?.application_status === 'Incomplete - Waitlisted' || program?.application_status === 'Waitlist - Not Interested' ? null :
                  <table className='table table-bordered table-layout-fixed text-center mt-4'>
                    <tr style={{ backgroundColor: '#ccc4' }}>
                      <th className='border border-1 pt-5 pb-5' rowSpan={2}>Payments</th>
                      <th className='border border-1'>Total Paid</th>
                      <th className='border border-1'>Outstanding Balance</th>
                      {!!nextPaymentDate && <th className='border border-1'>Next Payment Date</th>}
                      {!!nextPaymentDue && <th className='border border-1'>Next Payment Due</th>}
                    </tr>

                    <tr>
                      <td className='border border-1 fw-normal'>{currencyFormat.format(totalPaidandRefund)}</td>
                      <td className='border border-1 fw-normal'>{currencyFormat.format(outstandingAmount)}</td>
                      {!!nextPaymentDate && <td className='border border-1 fw-normal'>{nextPaymentDate}</td>}
                      {!!nextPaymentDue && <td className='border border-1 fw-normal'>{nextPaymentDue}</td>}
                    </tr>

                    <tr>
                      <td className='d-flex gap-3 justify-content-center pt-4'>
                        <Button 
                          className="btn btn-primary w-50" 
                          onClick={isDepositDue ? () => handlePayDeposit(program?.program_uuid!) : handlePayNow}
                        >
                          {isDepositDue ? 'Pay Deposit' : 'Pay Now'}
                        </Button>
                        
                        {isDepositDue && (
                          <Button 
                            className="btn btn-danger w-50"
                            onClick={() => handleDelete(program?.program_uuid!)}
                          >
                            Delete
                          </Button>
                        )}
                      </td>
                    </tr>
                  </table>
                }
              </div>
              )
          })}
          </div>
        )
      })}

      <ChangeStatusModal 
        show={showModal} 
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        selectedStatus={selectedStatus || ""}
        handleChangeStatus={handleChangeStatus}
        isError={isError || ""} 
        hasAdmin={hasAdmin}
        handleSubmitStatusChange={handleSubmitStatusChange}
        formData={formData}
      />

      <ResubmitRequestModal 
        show={showModalConfirmation}
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        isLoading={isLoading}
        showSuccessMessage={showSuccessMessage}
        handleSubmitRequest={handleSubmitRequest}
        formData={formData}
        currentUser={currentUser}
      />

      <ApproveDenyRequestModal 
        show={showModalConfirmationApproveDeny}
        handleClose={handleCloseModal}
        modalMessage={modalMessage}
        isLoading={isLoading}
        showSuccessMessage={showSuccessMessage}
        handleApproveAndDenyRequest={handleApproveAndDenyRequest}
        formData={formData}
        getAction={getAction}
      />

      <ScrollArrow />
    </div>
  );
};

export default EnrolledDashboard;
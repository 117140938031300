/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {useAuth} from '../../modules/auth'
import {useQuery} from 'react-query'
import useAppRequests from '../../modules/application/Hooks/useAppRequests'
import _queryKeys from '../../modules/application/core/_queryKeys'
import {ROLES} from '../../routing/core/_models'
import ConfirmPayBanner from '../../modules/application/components/ConfirmPay/ConfirmPayBanner'
import useRole from '../../modules/auth/hooks/useRole'
import { useNavigate } from 'react-router-dom'
import { IEnrollmentSummary } from '../../modules/application/core/_models'
import EnrolledDashboard from '../../NewTerra/EnrolledDashboard'
import { Alert, Button, Modal } from 'react-bootstrap'
import { axiosPrivate } from '../../../axiosInstance'
import '../../../app/NewTerra/DashboardWrapper.scss'
import SavedProgress from '../../NewTerra/SavedProgress'
const DashboardPage: FC = () => {
  const {getAllEnrollmentSummariesReq} = useAppRequests()

  const {
    isLoading,
    data: getEnrollments,
    refetch,
  } = useQuery(_queryKeys.getAllEnrollmentSummariesReq, getAllEnrollmentSummariesReq, {
    refetchInterval: 300000,
  })

  const oppLenght: any = getEnrollments || []

  if (isLoading) {
    return (
      <>
      <div className='w-100 my-10'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <output className='spinner-border text-primary'>
            <span className='visually-hidden'>Loading...</span>
          </output>
        </div>
      </div>
      </>
    )
  } else {
    if (oppLenght.length !== 0) {
      return (
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-lg-12'>
              <EnrolledDashboard data={getEnrollments} refetch = {refetch}/>
            </div>
          </div>
        </>
      )
    }
    return null;
  }
}

const DashboardAdmin: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div>Hello Admin</div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const {currentUser} = useAuth()
  const {isAdminOrSuperAdmin} = useRole()
  const [showIncompleteProfileModal, setShowIncompleteProfileModal] = useState(false)
  const [isProfileCheckLoading, setIsProfileCheckLoading] = useState(true) // Add load

  const navigate = useNavigate()

  const {data: enrollmentSummary} = useQuery<IEnrollmentSummary[]>(
    _queryKeys.getAllEnrollmentSummariesReq
  )

  const checkProfileCompletion = async () => {
    try {
      const response = await axiosPrivate.get('/auth/check-prerequisite')
      return response.data.statusCode === 10 // Assuming this means profile is complete
    } catch (error) {
      console.error('Error checking profile:', error)
      return false
    }
  }


  useEffect(() => {
    if (currentUser?.role === ROLES.parent) { // Check if the user is a parent
      const initialProfileCheck = async () => {
        setIsProfileCheckLoading(true) // Set loading state to true before starting the check
        const profileComplete = await checkProfileCompletion()
        setIsProfileCheckLoading(false) // Set loading state to false after check completes

        if (!profileComplete) {
          setShowIncompleteProfileModal(true)
        }
      }

      initialProfileCheck()
    }
  }, [currentUser?.role])

  const closeModal = () => {
    setShowIncompleteProfileModal(false);
  };

  const navigateToUpdateProfile = () => {
    navigate(`/profile/${currentUser?.currentClient}`);
    closeModal();
  };

  const [savedProgress, setSavedProgress] = useState<any[]>([])

  useEffect(() => {
    const fetchSavedApplications =  async () => {
      try {
        const response = await axiosPrivate.get('/save-chages/get')
        setSavedProgress(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    
    fetchSavedApplications()
  }, [])

  return (
    <div>
      {!isAdminOrSuperAdmin && (
        <>
          <div className='d-flex align-items-center position-relative'>
            <ConfirmPayBanner title={`${currentUser?.lastName} Family Dashboard`}/>
          </div>

          {(savedProgress.length === 0 && !enrollmentSummary) ?  (
            <>
            <Alert variant="info" className="d-flex align-items-center dashboard-container">
              <p className="fs-3 p-4 mb-0">
                Hello and welcome to your Summer Springboard Dashboard! Here you’ll find all the
                information you need to manage your account, track your progress, and stay updated on
                upcoming events and opportunities. Click the Create Application Button on the left Menu
                to start your application.
              </p>
            </Alert>
            </>
          ): null}
       
        </>
      )}
      <div className='alert alert-link d-flex align-items-center p-5 w-100'>
        <div className='d-flex flex-column w-100'>
          {currentUser?.role === ROLES.superAdmin ? <DashboardAdmin /> : (
            <>
            <SavedProgress savedProgress={savedProgress} />
            <DashboardPage />
            </>
          )}
        </div>
        {currentUser?.role === ROLES.parent && !isProfileCheckLoading && (
        <Modal show={showIncompleteProfileModal} keyboard={false} backdrop='static' onHide={closeModal}>
          <Modal.Header>
            <Modal.Title>Incomplete Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your profile is not yet completed. Would you like to update it now?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={navigateToUpdateProfile}>
              Update Profile
            </Button>
          </Modal.Footer>
        </Modal>
        )}
      </div>
    </div>
  )
}

export {DashboardWrapper}

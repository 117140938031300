import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getAllProgram, getAllTypeForm } from "../../network/api"
import { useNavigate } from 'react-router-dom';
import {useAuth} from '../modules/auth'
import { axiosPrivate } from '../../axiosInstance';

interface FormRendererProps {
    item: any;
    deadline: any;
    program: any;
    handleShowModal: Function;
    handleShowModalConfirmation: Function;
    handleShowModalConfirmationApproveDeny: Function;
    student_uuid: string;
    hasAdmin: boolean;
    data: any[];
    refetch: () => any ;
}

interface TypeformData {
    id: number;
    form_id: string | number;
    response_type: string;
    name: string;
    overview: string;
    category: string;
}

export const EnrolledFormRenderer: React.FC<FormRendererProps> = ({
    item,
    deadline,
    program,
    handleShowModal,
    handleShowModalConfirmation,
    handleShowModalConfirmationApproveDeny,
    student_uuid,
    hasAdmin,
    data,
    refetch,
}) => {

    const navigate = useNavigate()
    const {currentUser} = useAuth()

    const [getTypeformData, setTypeformData] = useState<TypeformData[]>([]);
    const [getApplicationStatusName, setApplicationStatusName] = useState(program?.application_status)

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!data || !getApplicationStatusName) return;
    
            setIsLoading(true);
            try {
                // Refetch the data if necessary
                if (typeof refetch === "function") {
                    await refetch();
                }
    
                // Fetch typeform data
                const { data: typeformData } = await getAllTypeForm();
                const categoryForms = typeformData.filter(
                    (form: { category: string }) => form.category === "application"
                );
                setTypeformData(categoryForms);
    
                const checkCompletedForms = async (program_uuid: any, selectedStatusValue: any) => {
                    try {
                        const payload = { program_uuid, selectedStatusValue };
                        await axiosPrivate.post('/api/contactprogram-updateApplicationStatus', payload);
                    } catch (error: any) {
                        console.error(error.message);
                    }
                };
    
                const specificFormId = item.typeform_data
                    .filter((item: any) => item.form_id === 9)
                    .map((item: any) => item.form_id);
    
                const otherCompletedForms = program.typeform_data
                    .filter((item: any) => item.response_type === 'Submitted - Needs Review')
                    .map((item: any) => item.form_id);
    
                const getCompletedForms = [...specificFormId, ...otherCompletedForms];
                const matchedTypeFormIds = categoryForms
                    .filter((form: any) => getCompletedForms.includes(form.id))
                    .map((form: any) => form.id);
    
                const uniqueFormIds = new Set(matchedTypeFormIds);
    
                if (
                    getApplicationStatusName === 'Incomplete - Deposit Paid' &&
                    uniqueFormIds.size === 3
                ) {
                    await checkCompletedForms(program.program_uuid, 'Received - Needs Review');
                    window.location.reload();
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [data, getApplicationStatusName]);

    const navigateForms = async (student_uuid: string, id: any, program_uuid: string, form_id: string|number, completedForms: any[], sf_id: string) => {
        try {
            setIsLoading(true);
            const data  = getTypeformData

            const formIdToFormId = data.reduce((acc: { [key: number]: string }, form: any) => {
                acc[form.id] = form.form_id;
                return acc;
            }, {});

            const matchedForm = data.find((form: any) => form.id === form_id);

            if (!matchedForm) {
                console.error('No matching form_id found.')
                return;
            }

            const formIds = data.map((form: { id: any; }) => ({
                form_id: form.id
            }))

            const formsForProgram = formIds || []

            const studentCompletedForms = item.typeform_data
                .filter((form: any) =>
                    form.form_id === 9 &&
                    ['Submitted - Needs Review', 'Completed'].includes(form.response_type) &&
                    form.student_uuid === student_uuid
                )
                .map((form: any) => form.form_id)

            const combinedCompletedForms = [...completedForms, ...studentCompletedForms];
            const completedFormsSet = new Set(combinedCompletedForms);
            const filterIncompleteForms = formsForProgram
                .filter((form: any) => !completedFormsSet.has(form.form_id))
                .map((form: any) => form.form_id)

            const currentForm = matchedForm.form_id
            const remainingForms = filterIncompleteForms
                .map((formId) => formIdToFormId[formId]) 
                .filter((form) => form && form !== currentForm)

            navigate(`/dashboard/${currentUser?.currentClient}/forms/${currentForm}`, { replace: true, state: {
                student_uuid: student_uuid,
                program_uuid: program_uuid,
                form_id: currentForm,
                remaining_forms: remainingForms,
                auth: currentUser?.currentClient,
                rawTypeformData: data,
                sf_id
            }})
        } catch (error) {
            console.error("Error handling navigation:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const getCompletedForms = (program: { typeform_data: any[]; }, item: { typeform_data: any[]; }) => {
        // Completed forms from the program
        const programCompletedForms = program.typeform_data
            .filter((item: any) => item.response_type === 'Submitted - Needs Review')
            .map((item: any) => item.form_id);

        return Array.from(new Set([...programCompletedForms]));
    };

    return (
        <>
            {isLoading ? (
                <tr>
                <td colSpan={6} style={{ textAlign: 'center' }}>
                    <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                    </div>
                </td>
                </tr>
            ) : (
                <>
                    {getTypeformData.filter(typeform => typeform.name === 'Medical Form').map((typeform, index) => {
                        const form_id = typeform.id;
                        const correspondingTypeform = item.typeform_data.find((typeform: { form_id: any; }) => typeform.form_id === form_id);

                        const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
                        const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;
                        
                        let status = 'Incomplete';
                        if (isCompleted) status = 'Completed';
                        else if (isSubmitted) status = 'Submitted - Needs Review';

                        const completedForms = getCompletedForms(program, item);

                        return (
                            <tr key={index} className='fw-normal'>
                                {index === 0 && (
                                    <td rowSpan={getTypeformData.length} className='fw-bold text-light' style={{ backgroundColor: '#a3a3a3' }} >
                                    Application Form
                                    </td >
                                )}
                                <td className='px-1'>{typeform?.name || 'N/A'}</td>
                                <td 
                                    className={`text-decoration-none ${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
                                    onClick={status === 'Incomplete' ? () => navigateForms(
                                    student_uuid, 
                                    program.selected_program.id, 
                                    program.program_uuid, 
                                    form_id!,
                                    completedForms,
                                    item?.contact_sf_id
                                    ) : undefined}
                                >
                                    {status}
                                </td>
                                <td>{deadline}</td>
                                <td style={{ textAlign: 'center'}}>
                                    {status === 'Incomplete' ? (
                                      <>
                                        <Button 
                                            variant="primary"
                                            style={{ width: '100px' }}
                                            onClick={() => navigateForms(
                                            student_uuid, 
                                            program.selected_program.id, 
                                            program.program_uuid, 
                                            form_id!,
                                            completedForms,
                                            item?.contact_sf_id
                                            )}
                                        >
                                            Submit
                                        </Button>

                                        <div className="dropdown-center d-flex flex-column justify-content-center align-items-center gap-2 text-align-center mt-2">
                                          {hasAdmin && (
                                            <Button
                                              variant="primary"
                                              style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }}
                                              className="toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              Modify
                                            </Button>
                                          )}

                                          <ul className="dropdown-menu">
                                            <li className='w-100 text-center mt-2'>
                                              <Button
                                                variant="light"
                                                style={{ color: 'black', width: '100%' }}
                                                onClick={() => handleShowModal(student_uuid, program.program_uuid, form_id, status, 'Medical')}
                                              >
                                                Form Status
                                              </Button>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {correspondingTypeform?.action && (
                                          <>
                                            {correspondingTypeform.action === 'resubmit' ? (
                                                <p style={{ fontSize: '14px', fontWeight: 700 }}>Resubmit requested</p>
                                            ) : (
                                                <p style={{ fontSize: '14px', fontWeight: 700 }}>Update requested</p>
                                            )}
                                          </>
                                        )}

                                        {status !== 'Incomplete' && (
                                          <div className="dropdown-center">
                                            {(hasAdmin || !correspondingTypeform?.action) && (
                                              <Button
                                                variant="primary"
                                                style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }}
                                                className="toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                Modify
                                              </Button>
                                            )}

                                            <ul className="dropdown-menu">
                                              {!correspondingTypeform?.action && (
                                                <>
                                                  <li className='mt-2'>
                                                    <Button
                                                      variant="light"
                                                      style={{ color: 'black' }}
                                                      onClick={() => handleShowModalConfirmation(student_uuid, program.application_status, null, form_id, 'resubmit')}
                                                    >
                                                      Request to resubmit
                                                    </Button>
                                                  </li>
                                                  {/* <li className='mt-2'>
                                                    <Button
                                                      variant="light"
                                                      style={{ color: 'black' }}
                                                      onClick={() => handleShowModalConfirmation(student_uuid, program.application_status, null, form_id, 'update')}
                                                    >
                                                      Request to update
                                                    </Button>
                                                  </li> */}
                                                </>
                                              )}

                                              {hasAdmin && (
                                                <li className='w-100 text-center mt-2'>
                                                  <Button
                                                    variant="light"
                                                    style={{ color: 'black', width: '100%' }}
                                                    onClick={() => handleShowModal(student_uuid, program.program_uuid, form_id, status, 'Medical')}
                                                    disabled={!correspondingTypeform}
                                                  >
                                                    Form Status
                                                  </Button>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        )}

                                        {hasAdmin && correspondingTypeform?.action && (
                                          <>
                                            <div className='d-flex flex-column justify-content-center align-items-center gap-2 text-align-center mt-2'>
                                              <Button 
                                                variant="success" 
                                                style={{ width: '100px' }} 
                                                onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, program.program_uuid, form_id, correspondingTypeform.action, 'Approve')}}
                                                >
                                                Approve
                                              </Button>
                                              <Button 
                                                variant="danger" 
                                                style={{ width: '100px' }} 
                                                onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, program.program_uuid, form_id, correspondingTypeform.action, 'Deny')}}
                                                >
                                                Deny
                                              </Button>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                </td>
                            </tr>
                        );
                    })}

                    {getTypeformData.filter(typeform => typeform.name !== 'Medical Form').map((typeform: any, index: any) => {
                        const form_id = typeform.id; 
                        const correspondingTypeform = program.typeform_data.find((typeform: { form_id: any; }) => typeform.form_id === form_id);
                        const displayNameOverview = getTypeformData.find((typeform: { id: any; }) => typeform.id === form_id);

                        const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
                        const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;

                        const completedForms = getCompletedForms(program, item);

                        let status = 'Incomplete';
                        if (isCompleted) status = 'Completed';
                        else if (isSubmitted) status = 'Submitted - Needs Review';

                        return (
                            <tr key={index} className='fw-normal'>
                                <td className='px-1'>{displayNameOverview?.name || 'N/A'}</td>
                                <td 
                                    className={`text-decoration-none ${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
                                    onClick={status === 'Incomplete' ? () => navigateForms(
                                    student_uuid, 
                                    program.selected_program.id, 
                                    program.program_uuid, 
                                    form_id!,
                                    completedForms,
                                    item?.contact_sf_id
                                    ) : undefined}
                                >
                                    {status}
                                </td>
                                <td>{deadline}</td>
                                <td style={{ textAlign: 'center'}}>
                                    {status === 'Incomplete' ? (
                                      <>
                                        <Button 
                                            variant="primary"
                                            style={{ width: '100px' }}
                                            onClick={() => navigateForms(
                                              student_uuid, 
                                              program.selected_program.id, 
                                              program.program_uuid, 
                                              form_id!,
                                              completedForms,
                                              item?.contact_sf_id
                                            )}
                                            >
                                            Submit
                                        </Button>

                                        <div className="dropdown-center d-flex flex-column justify-content-center align-items-center gap-2 text-align-center mt-2">
                                          {hasAdmin && (
                                            <Button
                                              variant="primary"
                                              style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }}
                                              className="toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              Modify
                                            </Button>
                                          )}

                                          <ul className="dropdown-menu">
                                            <li className='w-100 text-center mt-2'>
                                              <Button
                                                variant="light"
                                                style={{ color: 'black', width: '100%' }}
                                                onClick={() => handleShowModal(student_uuid, program.program_uuid, form_id, status)}
                                              >
                                                Form Status
                                              </Button>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {correspondingTypeform?.action && (
                                          <>
                                            {correspondingTypeform.action === 'resubmit' ? (
                                                <p style={{ fontSize: '14px', fontWeight: 700 }}>Resubmit requested</p>
                                            ) : (
                                                <p style={{ fontSize: '14px', fontWeight: 700 }}>Update requested</p>
                                            )}
                                          </>
                                        )}

                                        {status !== 'Incomplete' && (
                                          <div className="dropdown-center">
                                            {(hasAdmin || !correspondingTypeform?.action) && (
                                              <Button
                                                variant="primary"
                                                style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }}
                                                className="toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                Modify
                                              </Button>
                                            )}

                                            <ul className="dropdown-menu">
                                              {!correspondingTypeform?.action && (
                                                <>
                                                  <li className='mt-2'>
                                                    <Button
                                                      variant="light"
                                                      style={{ color: 'black' }}
                                                      onClick={() => handleShowModalConfirmation(student_uuid, program.application_status, program.program_uuid, form_id, 'resubmit')}
                                                    >
                                                      Request to resubmit
                                                    </Button>
                                                  </li>
                                                  {/* <li className='mt-2'>
                                                    <Button
                                                      variant="light"
                                                      style={{ color: 'black' }}
                                                      onClick={() => handleShowModalConfirmation(student_uuid, program.application_status, program.program_uuid, form_id, 'update')}
                                                    >
                                                      Request to update
                                                    </Button>
                                                  </li> */}
                                                </>
                                              )}

                                              {hasAdmin && (
                                                <li className='w-100 text-center mt-2'>
                                                  <Button
                                                    variant="light"
                                                    style={{ color: 'black', width: '100%' }}
                                                    onClick={() => handleShowModal(student_uuid, program.program_uuid, form_id, status)}
                                                    disabled={!correspondingTypeform}
                                                  >
                                                    Form Status
                                                  </Button>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      
                                        {hasAdmin && correspondingTypeform?.action && (
                                          <>
                                            <div className="d-flex flex-column justify-content-center align-items-center gap-2 text-align-center mt-2">
                                              <Button 
                                                variant="success" 
                                                style={{ width: '100px' }} 
                                                onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, program.program_uuid, form_id, correspondingTypeform.action, 'Approve')}}>
                                                  Approve
                                              </Button>
                                              <Button 
                                                variant="danger" 
                                                style={{ width: '100px' }} 
                                                onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, program.program_uuid, form_id, correspondingTypeform.action, 'Deny')}}>
                                                  Deny
                                              </Button>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </>
            )}
        </>
    );
};

// PredepartureStatusActions.tsx
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { axiosPrivate } from '../../../../axiosInstance/index';
import { useAuth } from '../../../modules/auth';
import PredepartureRequestModal from '../../../modules/application/components/modal/PredepartureRequestModal';
import ApproveDenyRequestModal from '../../../modules/application/components/modal/ApproveDenyRequestModal';
import { Link } from 'react-router-dom';

interface PredepartureActionsProps {
  hasAdmin: boolean;
  predeparture: any
  predepartureStatus: any[];
  program: any
  student_uuid: any
  refetch: () => any 
  sf_id?: string
}

const PredepartureAction: React.FC<PredepartureActionsProps> = ({
  hasAdmin,
  predeparture,
  predepartureStatus,
  program,
  student_uuid,
  refetch,
  sf_id,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showModalConfirmationApproveDeny, setShowModalConfirmationApproveDeny] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState({ success: '', error: ''});
  const [getAction, setAction] = useState('');
  const [formData, setFormData] = useState({
    studentUUID: null,
    applicationStatus: null,
    opportunity_id: null,
    predeparture_form_id: null,
    account_holder_uuid: null
  });
  const {currentUser} = useAuth()

  const handleShowModalConfirmation = (student_uuid: any, application_status: any, message: string, opportunity_id: any, predeparture_form_id: any, account_holder_uuid?: any) => {
    setModalMessage(message);
    setShowModalConfirmation(true);
    setFormData({
      studentUUID: student_uuid,
      applicationStatus: application_status,
      opportunity_id, 
      predeparture_form_id,
      account_holder_uuid: account_holder_uuid
    });
  };

  const handleShowModalConfirmationApproveDeny = (student_uuid: any, action: string, message: string, opportunity_id: any, predeparture_form_id: any, account_holder_uuid?: any) => {
    setModalMessage(message);
    setAction(action);
    setShowModalConfirmationApproveDeny(true);
    setFormData({
      studentUUID: student_uuid,
      applicationStatus: null,
      opportunity_id: opportunity_id,
      predeparture_form_id: predeparture_form_id,
      account_holder_uuid: account_holder_uuid
    });
  };

  const handleSubmitRequest = async(
    student_uuid: any, 
    application_status: any, 
    message: string, 
    opportunity_id: any, 
    predeparture_form_id: any, 
    account_holder_uuid?: string
  ) => {
    let response;
    try {
      setIsLoading(true);
      const data = { student_uuid, application_status, type: message, opportunity_id, predeparture_form_id, account_holder_uuid}
      console.log(data, 'data')
      if (predeparture?.task_title === 'waiver') {
        response = await axiosPrivate.post('/hellosign/waiver-request', data);
      } else {
        response = await axiosPrivate.post('/api/predeparture-update-resubmit-request', data);
      }

      if (response.status === 200) {
        setShowSuccessMessage({ success: response.data.message, error: ''})
        await refetch()
      }
      
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while submitting the form: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApproveAndDenyRequest = async(
    student_uuid: any, 
    action: any,
    message: string,
    opportunity_id: any, 
    predeparture_form_id: any, 
    account_holder_uuid?: any
  ) =>{
    try {
      setIsLoading(true);
      const data = { student_uuid, action, message, opportunity_id, predeparture_form_id, account_holder_uuid}
      const response = await axiosPrivate.post('/predeparture/approve_or_deny_request', data) 
      if (response.status === 200) {
        setShowSuccessMessage({ success: response.data.message, error: ''})
        await refetch()
      }

    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while processing the request: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleCloseModal = () => {
    setShowModalConfirmation(false);
    setShowModalConfirmationApproveDeny(false);
  };

  return (
    <>
      {predepartureStatus?.length > 0 &&
        predepartureStatus.map((item: any, index: number) => (
          <div key={index} className="text-align-center mt-2">

            {/* Show status messages based on actions */}
            {item.action && (
              <>
              <div className="text-align-center mt-3">
                {item.action === 'resubmit' ? (
                  <h3 style={{ fontSize: '14px', fontWeight: 700 }}>Resubmit requested</h3>
                ) : item.action === 'update' && (
                  <h3 style={{ fontSize: '14px', fontWeight: 700 }}>Update requested</h3>
                )}
              </div>
              </>
            )}

            {/* Only show buttons if predepartureStatus is not "Incomplete" */}
            {item.status?.name !== 'Incomplete' && (
              <div className="dropdown-center">
                {(hasAdmin || !item?.action) && (
                  <Button
                    variant="primary"
                    style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }}
                    className="toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Modify
                  </Button>
                )}

                <ul className="dropdown-menu">
                  {!item?.action && (
                    <>
                      <li className='mt-2'>
                        <Button
                          variant="light"
                          style={{ color: 'black' }}
                          onClick={() => handleShowModalConfirmation(student_uuid, program.application_status, 'resubmit', program.id, predeparture.id, currentUser?.uuid)}
                        >
                          Request to resubmit
                        </Button>
                      </li>
                      {/* <li className='mt-2'>
                        <Button
                          variant="light"
                          style={{ color: 'black' }}
                          onClick={() => handleShowModalConfirmation(student_uuid, program.application_status, 'update', program.id, predeparture.id, currentUser?.uuid)}
                        >
                          Request to update
                        </Button>
                      </li> */}
                    </>
                  )}

                  
                  <li className='mt-2'>
                    <Link
                        className="btn btn-light w-100"
                        style={{ color: 'black' }}
                        to={`/portal/${currentUser?.currentClient}/${predeparture.task_title?.toLowerCase()}/${program.id}`}
                        state={{ 
                          predeparture: { 
                            ...predeparture, 
                            predeparture_status: predepartureStatus && predepartureStatus.length > 0 ? predepartureStatus : [], 
                            student_uuid: student_uuid, 
                            program_uuid: program?.program_uuid,
                            sf_id: sf_id,
                          }
                        }}
                      >
                        View
                    </Link>
                  </li>
                  
                </ul>
              </div>
            )}

            {/* Admin logic for approval/decline */}
            {hasAdmin && item.action && (
              <div className="d-flex flex-column justify-content-center align-items-center gap-2 text-align-center mt-2">
                  <Button
                    variant="success"
                    style={{ width: '100px' }}
                    onClick={() => handleShowModalConfirmationApproveDeny(student_uuid, item.action, 'approved', program.id, predeparture.id, currentUser?.uuid)}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="danger"
                    style={{ width: '100px' }}
                    onClick={() => handleShowModalConfirmationApproveDeny(student_uuid, item.action, 'denied', program.id, predeparture.id, currentUser?.uuid)}
                  >
                    Deny
                  </Button>
              </div>
            )}
          </div>
        ))
      }
      <div>
        <PredepartureRequestModal 
          show={showModalConfirmation}
          handleClose={handleCloseModal}
          modalMessage={modalMessage}
          isLoading={isLoading}
          showSuccessMessage={showSuccessMessage}
          handleSubmitRequest={handleSubmitRequest}
          formData={formData}
          currentUser={currentUser}
        />

        <ApproveDenyRequestModal 
          show={showModalConfirmationApproveDeny}
          handleClose={handleCloseModal}
          modalMessage={modalMessage}
          isLoading={isLoading}
          showSuccessMessage={showSuccessMessage}
          handleApproveAndDenyRequest={handleApproveAndDenyRequest}
          formData={formData}
          getAction={getAction}
          currentUser={currentUser}
          type='Predeparture'
        />
      </div>
    </>
  );
};

export default PredepartureAction;

import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance, axiosPrivate } from '../../axiosInstance';
import { Widget } from '@typeform/embed-react';

const PredepartureTypeform = () => {

    const navigate = useNavigate()
    const location = useLocation();
    const {student_uuid, program_uuid, form_id, auth, sf_id, predeparture_id, opportunity_id}: any  = location.state || '' ;
    const [isError, setIsError] = useState<string | null>(null);

    const handleFormSubmit = async () => {
        console.log('submitting form')
        try {
            const data = { student_uuid, program_uuid, form_id: form_id, predeparture_id, opportunity_id}
            const response = await axiosPrivate.post('/api/predeparture-typeform-responses', data );
        
            if (response.status !== 200) {
                setIsError('Form did not sycn, please try again.')
                setTimeout(() => {}, 5000)
                return; 
            }

            setIsError(null);

            setTimeout(() => 
                navigate(`/dashboard/${auth}`)
            , 1000)
          
        } catch (error) {
            setIsError('An error occurred while submitting the form: ' + error);
        }
      };
    return (
        <>
        {isError && <p style={{ color: 'red' }}>{isError}</p>}  
        <div>
            {isError && <p style={{ color: 'red' }}>{isError}</p>}  
            <Widget
                height="700"
                id={form_id}
                className="my-form"
                hidden={{ student_uuid, program_uuid, sf_id }}
                onSubmit={handleFormSubmit}
            />
        </div>
        </>
    )
}

export default PredepartureTypeform
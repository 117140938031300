/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import InjectAxiosInterceptors from '../modules/errors/components/InjectAxiosInterceptors'
import EnrollPage from '../modules/application/EnrollPage'
import HelloSignRedirect from '../pages/dashboard/GLA/uploads/HelloSignRedirect'
import NewHomePage from '../NewTerra/newHomePage'
import ResendEmailRedirect from '../NewTerra/ResendEmailRedirect'
import EmailInvitationRedirect from '../NewTerra/EmailInvitationRedirect'
import TypeformButton from '../NewTerra/MedicalForm'
import PredepartureTypeform from '../NewTerra/PredepartureTypeform'
import PayLedger from '../NewTerra/PayLedger'
import PaymentOptions from '../NewTerra/PaymentOptions'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  console.log(currentUser, 'current user')
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <InjectAxiosInterceptors />
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />

          {/* <Route path='enroll/*' element={<EnrollPage />} /> */}
          <Route path='hellosign/sign/completed/:uuid/*' element={<HelloSignRedirect />} />
          <Route path='invitation/:action/:hash/:token' element={<EmailInvitationRedirect />} />
          <Route path='/auth/email-change-verify/:hash/:token' element={<ResendEmailRedirect />} />
          <Route path='/dashboard/:client/forms/:form_id' element={<TypeformButton />} />
          <Route path='/dashboard/:client/predeparture/:form_id' element={<PredepartureTypeform />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={`/dashboard/${currentUser.currentClient}`} />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              {/* <Route path='*' element={<PasswordForm />}/> */}
              <Route path='*' element={<NewHomePage />}/>
              {/* <Route path='*' element={<Navigate to='/auth' />} /> */}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}

import {useMutation, useQuery} from 'react-query'
import _queryKeys from '../../../modules/application/core/_queryKeys'
import useAppRequests from '../../../modules/application/Hooks/useAppRequests'
import {getAuthPromise, PREV_AUTH_LOCAL_STORAGE_KEY, useAuth} from '../../../modules/auth'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import ReactHTMLParser from 'react-html-parser';

interface Props {
  predeparture: any
  opportunity: string
}

const PredepartureTypeform = ({ predeparture }: Props) => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const { opportunity } = useParams()
  const [selectedOption, setSelectedOption] = useState('Submitted - Needs Review');
  const predepartureStatus = predeparture.predeparture_status?.[0]?.status?.name || 'Incomplete';

  const regex = /(?<=typeform\.com\/to\/)[^/?#]+/; 
  const match = predeparture?.party_link?.match(regex);
  const slicedPartyLink = match ? match[0] : null

  const [success, setSuccess] = useState<any>();
  const [showModal, setShowModal] = useState(false)
  const { updatePredepartureFormStatusReq } = useAppRequests();
  const [hasAdmin, setHasAdmin] = useState(false);
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const inputs = {
      opportunity_id: Number(opportunity),
      predeparture_id: predeparture?.id,
      predeparture_task_name: predeparture.name,
      status_name: selectedOption,
    }

    setShowModal(false);
    const response = await updatePredepartureFormStatusReq(inputs);
    setSuccess(response); 
    if (response.message === 'Predeparture Status successfully updated!') {
      setTimeout(() => {
        return navigate(`/dashboard/${currentUser?.currentClient}`, {replace: true})
      }, 3000)
    }
  }

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
  }

  const navigateForms = async (student_uuid: string, program_uuid: string, form_id: string|number, sf_id: string) => {
    try {
      navigate(`/dashboard/${currentUser?.currentClient}/predeparture/${form_id}`, { replace: true, state: {
        student_uuid: student_uuid,
        program_uuid: program_uuid,
        form_id: form_id,
        auth: currentUser?.currentClient,
        sf_id,
        predeparture_id: predeparture?.id,
        opportunity_id: Number(opportunity)
      }})
    } catch (error) {
      console.error("Error handling navigation:", error);
    }
  }

  const handleSelect = async (event: any) => {
    setSelectedOption(event.target.value);
  }

  useEffect(() => {
    if (predeparture) {

      const checkAdmin = async () => {
        const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
        if (prevAuth) {
          setHasAdmin(true)
        }
      }
      checkAdmin()
    }
  }, [predeparture])

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='d-flex justify-content-center align-items-center card-header rounded w-75 mx-auto mt-5' style={{ background: '#f7942a'}}>
            <h1 className='fw-bolder fs-2x text-light text-uppercase text-center'>
              Pre-Departure Tasks List - {predeparture.name}
            </h1>
          </div>
          <div style={{ fontSize: 20 }} className='card-header px-0 my-5 fw-bolder'>
            How to Complete Form:
          </div>
          <h3>{predeparture?.description || ''}</h3>
          <div className='instructions'>
            {ReactHTMLParser(predeparture?.instruction) || ''}
          </div>
          {predepartureStatus === 'Incomplete' && predeparture ? (
            predeparture?.task_type === 'typeform' ? (
              <button
                className='d-flex border-2 border-dashed border-primary bg-white justify-content-center w-25 mx-auto my-3 text-primary cursor-pointer py-2'
                onClick={(e) => {
                  navigateForms(
                    predeparture?.student_uuid,
                    predeparture?.program_uuid,
                    slicedPartyLink,
                    predeparture?.sf_id
                  );
                }}
              >
                Click here to access Forms
              </button>
            ) : (
              <a
                target='_blank'
                rel='noreferrer'
                className='d-flex border-2 border-dashed border-primary bg-white justify-content-center w-25 mx-auto my-3 text-primary cursor-pointer py-2'
                href={predeparture?.party_link}
              >
                Click here to access Forms
              </a>
            )
          ) : null}
        </div>

        <form className="d-flex flex-column w-25 mx-5">
          {hasAdmin &&
            <select
              className="form-select mb-5"
              value={selectedOption}
              onChange={handleSelect}>
              <option>Review Predeparture Task</option>
              <option value="Incomplete">Incomplete</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Not Applicable">Not Applicable</option>
            </select>}

          <div className='mb-7'>
          {hasAdmin ? (
            <div className='btn btn-primary'  onClick={() => setShowModal(true)}>
              Submit
            </div> ) : (
             <div className={`btn btn-primary ${predepartureStatus === 'Confirmed' || predepartureStatus === 'Submitted - Needs Review' 
             || predeparture.status === 'Not Applicable'
             ? 'disabled' : ''}`} 
             onClick={() => {
               if (predepartureStatus !== 'Confirmed' && 
                   predepartureStatus !== 'Submitted - Needs Review' && 
                   predepartureStatus !== 'Not Applicable'
                  ) {
                 setShowModal(true);
               }
             }}>
              Submit
            </div>
            )}
            <div className='mt-5'>
              {success && 
              <span className={success.message == "Predeparture Status successfully updated!"
              ? "w-50 text-center text-success fw-bold" : 
                "w-50 text-center text-danger fw-bold p-5 mt-3"}>
                {success.message}
              </span> }
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop='static' keyboard={false} centered>
              <Modal.Body className='p-0'>
                <Modal.Header closeButton>
                  <Modal.Title>Are you sure you want to submit?</Modal.Title>
                </Modal.Header>
              </Modal.Body>
              <Modal.Footer style={{padding: '20px 30px'}}>
                
                <Button 
                  variant='secondary' 
                  onClick={handleSubmit}
                >
                  Yes
                </Button>
                <Button variant='danger' className='ms-2' onClick={handleClose}>
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : 'No'}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </form>
      </div>
    </>
  )
}

export default PredepartureTypeform

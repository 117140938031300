import React, { useEffect, useState, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Button, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { IDiscountInfo, IPaymentMethods, IProgramPayment } from '../modules/application/core/_models';
import { useQuery } from 'react-query';
import useAppRequests from '../../../src/app/modules/application/Hooks/useAppRequests';
import { methodType, Payment } from './PayLedger';
import PaymentMethodInputsV2 from './PaymentMethodInputV2';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosPrivate } from '../../axiosInstance';
import { capitalizeFirstLetter, currencyFormat } from '../../utils/funcHelpers';
import CongratulationsModal from '../modules/application/components/modal/CongratulationsModal';
import { useAuth } from '../modules/auth';

type CreditCardInfo = {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  billingAddress: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
};

type BankAccountInfo = {
  nameOnCard: string;
  routingNumber: string;
  accountNumber: string;
  bankType: 'checking' | 'savings';
};

type PaymentType = 'creditCard' | 'bankAccount' | 'debitCard' | '';

interface IAlertMsg {
  success?: string;
  error?: string;
}

interface IPaymentDetails {
  due_now: number;
  full_amount: number;
  ledger_transaction: Record<string, any>[];
  selected_program: Record<string, any>;
  selected_session: Record<string, any>;
  student_type: string;
  traveler_uuid?: string
}

const initialPaymentDetails: IPaymentDetails = {
  due_now: 0,
  full_amount: 0,
  ledger_transaction: [],
  selected_program: {},
  selected_session: {},
  student_type: ''
}

const PaymentOptions = () => {
  const navigate = useNavigate()
  const { program_uuid } = useParams()
  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetails>(initialPaymentDetails)
  const [paymentType, setPaymentType] = useState<PaymentType>(''); 
  const [selectPMethod, setSelectPMethod] = useState<string>('');
  const [selectedFunding, setSelectedFunding] = useState<string>('');
  const [payAmount, setPayAmount] = useState<'payInFull' | 'payDue' | null>(null);
  const [isWaitlist, setIsWaitlist] = useState<boolean>(false);

  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeData, setDiscountCodeData] = useState<IDiscountInfo[]>([]);

  const [alertMsg, setAlertMsg] = useState<IAlertMsg>({});

  const [creditCardInfo, setCreditCardInfo] = useState<CreditCardInfo>({
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
    billingAddress: '',
    billingCity: '',
    billingStateProvince: '',
    billingPostalCode: '',
    billingCountry: '',
  });

  const [bankAccountInfo, setBankAccountInfo] = useState<BankAccountInfo>({
    nameOnCard: '',
    routingNumber: '',
    accountNumber: '',
    bankType: 'checking'
  });

  const { getAllPaymentMethods, useVerifyDiscountCode, useAddDiscountToLedger, usePayProgram } = useAppRequests();

  const {
    data: paymentMethod,
    isLoading: isPaymentMethodLoading
  } = useQuery<any>("getAllPaymentMethods", getAllPaymentMethods);

  const verifyDiscount = useVerifyDiscountCode();
  const addDiscountToLedger = useAddDiscountToLedger();
  const payProgram = usePayProgram();

  const discounts = paymentDetails.ledger_transaction.filter(
    (item) => 
      item.transaction_type === 'discount' || 
      item.transaction_type === 'coupon' || 
      item.transaction_type === 'scholarship'
  )

  const hasDiscount = (discounts.length + discountCodeData.length) >= 1;

  const [selectedMethod, setSelectedMethod] = useState('');
  const [pMethod, setPMethod] = useState<Payment[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(isPaymentMethodLoading);
  const [submit, setSubmit] = useState<boolean>(false);
  const [showCongratulationsModal, setShowCongratulationsModal] = useState(false);
  const {currentUser} = useAuth();
  const [payment, setPayments] = useState<IProgramPayment>({
    programs_to_pay: [
      {
        program_uuid: program_uuid || '',
        pay_amount: Number(payAmount),
        payment_schedule: payAmount === 'payDue' ? true : false,
    }
    ],
    payment_info: {
      payment_type: '',
      isActive: false,
      ...(paymentType === 'creditCard' || paymentType === 'debitCard' ? { creditCardInfo } : { bankAccountInfo }),
    },
    existingPaymentMethod: selectedFunding.includes('funding_') ? selectedFunding : '',
  })

  const handlePMethodSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPMethod(e.target.value);
    setSelectedFunding(e.target.value);
  };

  const fetchPaymentDetails = useCallback(async () => {
    try {
      const response = await axiosPrivate.get(`/get-payment-details/${program_uuid}`)
      setPaymentDetails(response.data.data)
    } catch (error) {
      console.error(error)
    }
  }, [program_uuid])

  const getFullPayment = () => {
    const currentLedger = paymentDetails?.ledger_transaction;
    const scholarship = currentLedger?.filter((item: any) => item.transaction_type === 'scholarship');
    const reduceFull = scholarship?.reduce((acc: number, item: any) => acc + Math.abs(item.paid), 0);
    const updatedFull = Math.max(paymentDetails?.full_amount - reduceFull, 0);
    const duePayment = paymentDetails?.due_now;
    const fullpayment = updatedFull === 0 ? duePayment : updatedFull;

    return fullpayment;
  }

  const submitCode = async () => {
    try {
      if (hasDiscount) return;

      const enrollmentData = {
        enrollee_uuid: paymentDetails?.traveler_uuid,
        payment_reference: null,
        balance: paymentDetails.full_amount,
        due_now: paymentDetails.due_now,
        program_uuid,
      }

      const discount = await verifyDiscount.mutateAsync(discountCode);

      const isAlreadyApplied = 
        discountCodeData.some(item => item.id === discount.id) || 
        paymentDetails.ledger_transaction.some(item => discount.description === item.description); 
      
      if (isAlreadyApplied) 
        return setAlertMsg({ error: `You've already used this ${discount.transaction_type} code` })

      const isCouponApplied = 
        discountCodeData.some(item => item.transaction_type === 'coupon') || 
        paymentDetails.ledger_transaction.some((item) => item.transaction_type === 'coupon')
      
      if (discount.transaction_type === 'coupon' && isCouponApplied) 
        return setAlertMsg({ error: 'You can only use one coupon per application' })

      if (discount.expired) 
        return setAlertMsg({ error: 'The code you have entered has expired' });

      await addDiscountToLedger.mutateAsync({ discountData: discount, enrollmentData })

      const discountValue = discount.transaction_type === 'coupon' ? 0 : (discount.price || discount.due);
      const discountDue = discount.transaction_type === 'coupon' ? discount.price : 0;

      setPaymentDetails((previousData) => (
        { ...previousData, 
          full_amount: previousData.full_amount - discountValue, 
          due_now: previousData.due_now - discountDue 
        }
      ))

      setDiscountCodeData((previousData) => [...previousData, discount]);
      setAlertMsg({ success: capitalizeFirstLetter(`${discount.transaction_type} code applied successfully!`) });
    } catch(error) {
      setAlertMsg({ error: 'The code you have entered does not exist - please confirm and re-submit' });
    } finally {
      setDiscountCode('');
      setTimeout(() => {
        setAlertMsg({});
      }, 3000);
    }
  } 

  useEffect(() => {
    const isWaitlist = sessionStorage.getItem('isWaitlist')
    const isWaitlisted = isWaitlist === 'true'

    if (isWaitlist) setIsWaitlist(isWaitlisted)
    if (!isWaitlisted) fetchPaymentDetails()
  }, [fetchPaymentDetails])

  useEffect(() => {
    const selectedPaymentAmount =
      payAmount === 'payInFull'
      ? paymentDetails.full_amount
      : payAmount === 'payDue'
      ? paymentDetails?.due_now
      : null;

    const updatedPayment = {
      programs_to_pay: [
        {
          program_uuid: program_uuid,
          pay_amount: selectedPaymentAmount,
          payment_schedule: payAmount === 'payDue',
        },
      ],
      payment_info: {
        payment_type: paymentType,
        isActive: false,
        ...(paymentType === 'creditCard' || paymentType === 'debitCard' ? { creditCardInfo } : { bankAccountInfo }),
      },
      existingPaymentMethod: selectedFunding.includes('funding_') ? selectedFunding : '',
    };
  
    setPayments(updatedPayment as any);
  }, [program_uuid, paymentType, creditCardInfo, bankAccountInfo, payAmount, selectedMethod, selectedFunding, selectPMethod, paymentDetails.full_amount]);

  useEffect(() => {
    if (paymentMethod && paymentMethod?.status === 200) {
      setPMethod(paymentMethod.data?.payment_methods);
      setIsLoading(false);
    }
  }, [paymentMethod])


  const handlePaymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentType(e.target.id as PaymentType);
  };

  const handleInputChange = <T extends CreditCardInfo | BankAccountInfo>(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    setInfo: React.Dispatch<React.SetStateAction<T>>
  ) => {
    let { name, value } = e.target;

    if (name === 'billingCity') value = value.normalize('NFD').replace(/[^a-zA-Z0-9 ]/g, '');

    setInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePayAmountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayAmount(e.target.value as 'payInFull' | 'payDue');
  };

  const renderTooltip = (text: string) => (
    <Tooltip id="tooltip">{text}</Tooltip>
  );

  const pMethodSaved = (!pMethod) ? null : pMethod?.filter((pm) => pm.isActive) 
  const pMethodSavedCredit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'creditCard')
  const pMethodSavedACH = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'bankAccount')
  const pMethodSavedDebit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'debitCard')

  let ccCount = 0
  let ACHCount = 0
  let dcCount = 0
  if (pMethodSavedCredit)  ccCount = pMethodSavedCredit.length
  if (pMethodSavedACH) ACHCount = pMethodSavedACH.length
  if (pMethodSavedDebit) dcCount = pMethodSavedDebit.length

  const paymentAmount =
  payAmount === 'payInFull'
    ? paymentDetails.full_amount
    : payAmount === 'payDue'
    ? paymentDetails.due_now
    : 0;

  const serviceFee = paymentType === 'creditCard' ? paymentAmount * 0.0275 : 0;
  const amountDueNow = paymentAmount + serviceFee;

  const onSubmit = async (e: any) => {
    e.preventDefault()
    setSubmit(true)
    try {
      if(!isWaitlist) {
        const response = await payProgram.mutateAsync(payment);

        if(response.status === 200) {
          setShowCongratulationsModal(true);
        }
      } else {
        const { payment_info, existingPaymentMethod } = payment;
        if(existingPaymentMethod != "") {
          const fundingId = existingPaymentMethod.split("_")[1];
          const selectData = {
            card_holder_uuid: currentUser?.uuid ?? '',
            id: Number(fundingId)
          }
          const {status} = await axiosPrivate.post<IPaymentMethods>(`/set_active`, selectData)
          if(status === 200) {
            const forWaitlistRaw = sessionStorage.getItem('forWaitlist');
            const addToWaitlist = forWaitlistRaw ? JSON.parse(forWaitlistRaw) : null;
            if(addToWaitlist){
              const response = await axiosPrivate.post('/enroll-program-v2', addToWaitlist);
              const { statusCode } = response.data;
              if(response.status === 200 && statusCode === 4) {
                navigate(`/dashboard/47821104`);
              }
            }
          }
        } else {
          const {payment_type, ...paymentInfo} = payment_info;
          const inputs = {
            card_holder_uuid: currentUser?.uuid ?? '',
            paymentType: payment_type,
            ...paymentInfo
          }
          const {status} = await axiosPrivate.post<IPaymentMethods>(`/auth/payment-method/add`, inputs)
          if(status === 200) {
            const forWaitlistRaw = sessionStorage.getItem('forWaitlist');
            const addToWaitlist = forWaitlistRaw ? JSON.parse(forWaitlistRaw) : null;
            if(addToWaitlist){
              const response = await axiosPrivate.post('/enroll-program-v2', addToWaitlist);
              const { statusCode } = response.data;
              if(response.status === 200 && statusCode === 4) {
                navigate(`/dashboard/47821104`);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      setAlertMsg(
        { error: 'Your payment was not processed successfully. Please review your payment details or try an alternative payment method. If you believe this is an error, we recommend checking with your bank or payment provider for more information.' }
      );
    } finally {
      setTimeout(() => {
        setAlertMsg({});
      }, 60000);

      setSubmit(false)
    }
  };

  return (
    <div className='content-to-hide'>
      <div style={{ background: 'white', padding: '20px 0', position: 'fixed', zIndex: 1000 }} className='d-flex w-75 align-items-center'>
        <div style={{ width: 150 }}>
          <Button onClick={() => isWaitlist ? navigate(-1) : navigate('/dashboard/47821104')} className='w-100' style={{ fontSize: 20 }}  variant="secondary" type="button">
              Back
          </Button>
        </div>
        <div style={{ background: '#f7942a' }} className='d-flex justify-content-center rounded w-75 mx-auto col-md-12'>
          <div className='d-flex justify-content-between align-items-center mx-5 w-100 text-center py-2 text-light border-radius-20'>
            <div></div>
            <h1 className='fw-bolder fs-2x text-light'>Payments</h1>
            <div style={{ fontSize: 10}}>Page 11</div>
          </div>
        </div>
        <div style={{ width: 150 }}></div>
      </div>
      <div className="container mx-auto content-to-hide" style={{ position: 'relative', padding: '100px 0 0' }}>
        <h2 className='mb-3'>
          <strong>Please select a payment method: </strong> Credit Card includes a 2.75% service fee.
        </h2>

        {isWaitlist && (
          <h2 className=" mb-3" style={{ color: 'red' }}>
            You will not be charged while your student is on the waitlist. If a spot opens up and you choose to enroll,
            we will charge you at that time with the saved payment method. If no saved payment method is provided, 
            application will be considered incomplete and your student will not be put on the waitlist.
          </h2>
        )}
        
        <form onSubmit={onSubmit}>
        <div className='d-flex flex-column flex-md-row gap-3'>
        <div className="accordion col-md-3 mb-5" id="paymentMethodAccordionWrapper">
          {methodType.map((method) => (
            <div className="accordion-item" key={method.type}>
              <h2 className="accordion-header" id={`heading_${method.type}`}>
                <button
                  className={`accordion-button fs-4 ${paymentType === method.type ? '' : 'collapsed'}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse_${method.type}`}
                  aria-expanded={paymentType === method.type ? 'true' : 'false'}
                  aria-controls={`collapse_${method.type}`}
                  onClick={(e: any) => handlePaymentTypeChange(e)}
                  id={method.type}
                >
                  {method.name}
                </button>
              </h2>
              <div
                id={`collapse_${method.type}`}
                className={`accordion-collapse collapse ${paymentType === method.type ? 'show' : ''}`}
                aria-labelledby={`heading_${method.type}`}
                data-bs-parent="#paymentMethodAccordion"
              >
                <div className="accordion-body">
                  <PaymentMethodInputsV2
                    paymentType={paymentType}
                    selectPMethod={selectPMethod}
                    pMethodSaved={pMethodSaved}
                    pMethodSavedCredit={pMethodSavedCredit}
                    pMethodSavedACH={pMethodSavedACH}
                    pMethodSavedDebit={pMethodSavedDebit}
                    ccCount={ccCount}
                    ACHCount={ACHCount}
                    dcCount={dcCount}
                    handlePMethodSelect={handlePMethodSelect}
                    handleInputChange={handleInputChange}
                    creditCardInfo={creditCardInfo}
                    bankAccountInfo={bankAccountInfo}
                    setCreditCardInfo={setCreditCardInfo}
                    setBankAccountInfo={setBankAccountInfo}
                  />
                </div>
              </div>
            </div>
        ))}
        </div>

        {(selectedFunding === 'bankAccount' && paymentType === 'bankAccount') && (
          <div className='col-md-6'>
            <h4>Bank Account Information:</h4>
            <div className='mb-3'>
              <label className='form-label'>Name on Account</label>
              <input
                type='text'
                name='nameOnCard'
                className='form-control form-control-solid form-control-lg'
                placeholder='Name on Account'
                value={bankAccountInfo.nameOnCard}
                onChange={(e) =>
                  handleInputChange(e, setBankAccountInfo)
                }
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Routing Number</label>
              <input
                type='text'
                name='routingNumber'
                className='form-control form-control-solid form-control-lg'
                placeholder='Routing Number'
                value={bankAccountInfo.routingNumber}
                onChange={(e) =>
                  handleInputChange(e, setBankAccountInfo)
                }
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Account Number</label>
              <input
                type='text'
                name='accountNumber'
                className='form-control form-control-solid form-control-lg'
                placeholder='Account Number'
                value={bankAccountInfo.accountNumber}
                onChange={(e) =>
                  handleInputChange(e, setBankAccountInfo)
                }
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Account Type</label>
              <select
                name='bankType'
                className='form-control form-control-solid form-control-lg'
                value={bankAccountInfo.bankType}
                onChange={(e) => handleInputChange(e, setBankAccountInfo)}
              >
                <option value='checking'>Checking</option>
                <option value='savings'>Savings</option>
              </select>
            </div>
          </div>
        )}

        {((selectedFunding === 'creditCard' && paymentType === 'creditCard') || (selectedFunding === 'debitCard' && paymentType === 'debitCard')) && (
          <div style={{ flex: 1 }}>
            <h4>Card Information</h4>
              <div className="row mb-3">
              <div className="col-md-6">
              <label className="form-label">Name on Card</label>
              <input
                type="text"
                name="nameOnCard"
                className="form-control form-control-solid form-control-lg"
                placeholder="Name on Card"
                value={creditCardInfo.nameOnCard}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
              </div>

            <div className='d-flex flex-column mb-7 fv-row col-md-6'>
              <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>
              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control form-control-solid form-control-lg'
                  placeholder='Enter card number'
                  name='cardNumber'
                  value={creditCardInfo.cardNumber}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />

                <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                    alt=''
                    className='h-25px'
                  />
                </div>
              </div>
            </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="form-label">Expiry Month</label>
                <select
                  name="cardExpiryMonth"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="MM"
                  value={creditCardInfo.cardExpiryMonth}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                >
                  <option value='' disabled>Select Month</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                </select>
              </div>
              <div className="col-md-4">
                <label className="form-label">Expiry Year</label>
                <select
                  name="cardExpiryYear"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="YYYY"
                  value={creditCardInfo.cardExpiryYear}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                >
                  <option value='' disabled>Select expiry year</option>
                  <option value='2021'>2021</option>
                  <option value='2022'>2022</option>
                  <option value='2023'>2023</option>
                  <option value='2024'>2024</option>
                  <option value='2025'>2025</option>
                  <option value='2026'>2026</option>
                  <option value='2027'>2027</option>
                  <option value='2028'>2028</option>
                  <option value='2029'>2029</option>
                  <option value='2030'>2030</option>
                  <option value='2031'>2031</option>
                </select>
              </div>
              <div className="col-md-4">
                <label className="form-label">CVV</label>
                <input
                  type="text"
                  name="cardCvv"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="CVV"
                  value={creditCardInfo.cardCvv}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Billing Address</label>
              <input
                type="text"
                name="billingAddress"
                className="form-control form-control-solid form-control-lg"
                placeholder="Billing Address"
                value={creditCardInfo.billingAddress}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                  <label className="form-label">Country</label>
                  <select
                    name="billingCountry"
                    className="form-select form-select-lg form-select-solid"
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  >
                    <option value="">Select Country</option>
                    {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => (
                      <option key={c.alpha_3 + '_' + idx} value={c.alpha_3}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              <div className="col-md-6">
                <label className="form-label">State/Province</label>
                <select
                  name="billingStateProvince"
                  className="form-select form-select-lg form-select-solid"
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  disabled={!creditCardInfo.billingCountry}
                >
                  <option value="">Select State/Province</option>
                  {COUNTRIES_ISO_3166_ALPHA_3.find((c) => c.alpha_3 === creditCardInfo.billingCountry)?.divisions?.map((d) => (
                    <option key={d.code} value={d.code}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Postal Code</label>
                <input
                  type="text"
                  name="billingPostalCode"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="Postal Code"
                  value={creditCardInfo.billingPostalCode}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">City</label>
                <input
                  type="text"
                  name="billingCity"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="City"
                  value={creditCardInfo.billingCity}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
            </div>
          </div>
        )}
        </div>
        
        {!isWaitlist && paymentDetails.full_amount ? (
          <>
            <div className='mb-8'>
              <h3>Billing Summary</h3>

              <div className='card p-4 fs-5'>
                {paymentDetails.ledger_transaction
                  ?.filter(item => item.transaction_type !== "removed")
                  .sort((a, b) =>   
                    a.transaction_type === "discount" || a.transaction_type === 'scholarship' || a.transaction_type === 'coupon' ? 1 :
                    b.transaction_type === "discount" || b.transaction_type === 'scholarship' || b.transaction_type === 'coupon' ? -1 :
                    b.description.length - a.description.length)
                  .map((item) => (
                    <div className='d-flex justify-content-between'>
                      <p>{item.transaction_type === 'coupon' && <span className='fw-bold'>Coupon:</span>} {item.description}</p>
                      <span>{currencyFormat.format(item.paid)}</span>
                    </div>
                  ))}

                {discountCodeData
                  .filter(item => item.transaction_type !== "removed")
                  .map(item => (
                    <div className='d-flex justify-content-between'>
                      <p>{item.transaction_type === 'coupon' && <span className='fw-bold'>Coupon:</span>} {item.description}</p>
                      <span>- {currencyFormat.format(item.price || item.due)}</span>
                    </div>
                  ))
                }

                <div className='d-flex justify-content-between fw-bold fs-4'>
                  <p className='mb-0'>Total</p>
                  <span>{currencyFormat.format(paymentDetails.full_amount)}</span>
                </div>
              </div>
            </div>

            {alertMsg.success && <Alert variant='success'>{alertMsg.success}</Alert>}
            {alertMsg.error && <Alert variant='danger'>{alertMsg.error}</Alert>}

            {!hasDiscount && discountCodeData.length < 1 && 
              <div className='card p-4 d-flex flex-column fs-3 gap-3 mb-4'>
                <h4 className='fw-light'>
                  If you were given a discount code, please enter it below:
                </h4>
                <span className='fw-bold' style={{fontSize: '15px'}}>
                  All codes are case-sensitive.
                </span>

                <div className='d-flex fs-3 gap-3 col-md-3' style={{ width: '370px' }}>
                  <input
                    className='form-control form-control-lg'
                    placeholder='Discount Code'
                    name='discountCode'
                    maxLength={30}
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                  <Button 
                    className='btn btn-hover w-100 fw-bolder fs-5' 
                    onClick={submitCode} 
                    disabled={verifyDiscount.isLoading || !discountCode}
                  > 
                    {verifyDiscount.isLoading ? 'Submiting...' : 'Submit Code'}
                  </Button>
                </div>
              </div>
            }

            <div className="mb-3 col-md-6">
              <div className='d-flex gap-1'>
                <label className="form-label">Pay Amount</label>
                {payAmount === 'payDue' && (
                  <div className='cursor-pointer'>
                    <OverlayTrigger
                      placement="right"
                      overlay={renderTooltip(`For families enrolled in the payment plan, 
                        remaining balance will be split into three payments: March 10, 
                        April 10, May 10. Applications submitted after the listed payment dates, 
                        the remaining balance will be split between the remaining date(s). 
                        Applications submitted after May 10, full balance is due within 48 hours of acceptance.`)}
                    >
                      <span className="tooltip-icon ms-2">
                        <IoIosInformationCircleOutline size={20} />
                      </span>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
                <select required className="form-select form-select-lg col-md-6" value={payAmount || ''} onChange={handlePayAmountChange}>
                  <option value="" disabled>Select Payment Option</option>
                  <option value="payInFull">Pay in Full</option>
                  <option value="payDue">Pay Deposit + Payment Plan</option>
                </select>
            </div>

            <div className='d-flex align-items-end gap-3'>
              <div className="card col-md-4 p-3 rounded" style={{ width: '60%' }}>
                <table className="card-body">
                  <tbody>
                    <tr className="fs-3">
                      <td>{payAmount === 'payDue' ? 'Deposit + Application Fee' : 'Pay in Full'}</td>
                      <td>{currencyFormat.format(paymentAmount)}</td>
                    </tr>
                    {serviceFee > 0 && (
                      <tr className="fs-3">
                        <td>Service Fee</td>
                        <td>{currencyFormat.format(serviceFee)}</td>
                      </tr>
                    )}
                    <tr className="fs-3 fw-bolder">
                      <td><strong>Amount Due Now:</strong></td>
                      <td><strong>{currencyFormat.format(amountDueNow)}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button type='submit' disabled={!selectedFunding || payProgram.isLoading} className='fs-5 px-5 fw-bolder'>
                {payProgram.isLoading ? 'Submitting...' : 'Pay Now'}
              </Button>
            </div>
          </>
        ) : (
          <div className='d-flex align-items-end gap-3'>
            <Button variant='primary' type='submit' disabled={submit} className='fs-5 px-5 fw-bolder'>
              {submit ? 'Submitting...' : 'Save Payment'}
            </Button>
          </div>
        )}
        </form>
      </div>
      <CongratulationsModal
        show={showCongratulationsModal}
        handleClose={() => {
          setShowCongratulationsModal(false);
          navigate('/dashboard/47821104');
        }}
      />
    </div>
  );
};

export default PaymentOptions;
